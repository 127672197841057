import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {Col, Row} from "react-bootstrap";


import HttpResponseErrorMessages from "@GlobalData/Messages/HTTP/Response/Errors";
import SystemErrorMessages from "@GlobalData/Messages/System/Errors";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";

import MessageComponent from "@Components/Elements/Messages/Content";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import LeagueTableHeadComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Table/Head";
import LeagueTableTeamComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues/Table/Team";


import TableByLeagueGamesGenerator from "@DataGenerators/Leagues/Tables/TableByLeagueGames";


const TEXTS: any = {
    PL: {
        no_league_selected_message_text: "Nie wybrani Ligi",
        no_teams_message_text: "Brak drużyn",
        no_games_message_text: "Brak meczów",
    },
    EN: {
        no_league_selected_message_text: "No league selected",
        no_teams_message_text: "No teams",
        no_games_message_text: "No games",
    }
};


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";
import LeagueGroupInterface from "../../../../../../../../Services/Interfaces/Api/Leagues/Leagues/Group";
import LeaguesTableTeamComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Table/Team";


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
    leagueGroups?: LeagueGroupInterface[] | null;
}


export interface LeagueTableComponentProps {
    selectedLeagueID: number;
    selectedLeague: SeasonLeagueInterface | null;
}


export const LeagueTableComponent: React.FC<LeagueTableComponentProps> = ({
    selectedLeagueID, selectedLeague
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        no_league_selected_message_text: NO_LEAGUE_SELECTED_MESSAGE_TEXT,
        no_teams_message_text: NO_TEAMS_MESSAGE_TEXT,
        no_games_message_text: NO_GAMES_MESSAGE_TEXT,
    } = TEXTS[activeLanguage];


    const LEAGUE_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUE.MAIN_URL;
    const LEAGUE_GAMES_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.GAMES.GAMES.MAIN_URL;


    const [leagueGames, setLeagueGames] = useState<LeagueGameInterface[] | null>(null);
    const [leagueGamesError, setLeagueGamesError] = useState<ErrorMessageInterface | null>(null);

    const [leagueTeams, setLeagueTeams] = useState<LeagueTeamInterface[] | null>(null);
    const [leagueTeamsError, setLeagueTeamsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!LEAGUE_GAMES_API_URL || !selectedLeagueID) return;

        setLeagueTeams(null);
        setLeagueGames(null);

        const getLeagueTeamsAndRounds = async () => {

            try {

                const response = await REST_API_REQUEST(`${LEAGUE_API_URL}/${selectedLeagueID}/league-teams/`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setLeagueTeams(responseContent);
                } else {
                    setLeagueTeamsError({errorCode: response.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueTeamsError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

            try {

                const response = await REST_API_REQUEST(`${LEAGUE_GAMES_API_URL}/?league_game_league=${selectedLeagueID}&league_game_round__league_round_type=1&all_results=true`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setLeagueGames(responseContent);
                } else {
                    setLeagueGamesError({errorCode: response.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueGamesError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

        };

        getLeagueTeamsAndRounds();

    }, [selectedLeagueID]);


    useEffect(() => {
        return () => {
            setLeagueGames(null);
            setLeagueGamesError(null);
            setLeagueTeams(null);
            setLeagueTeamsError(null);
        }
    }, []);

    if (!!leagueGamesError) return <ErrorMessageComponent lg={8} messageText={leagueGamesError.errorMessage} />;
    if (!!leagueTeamsError) return <ErrorMessageComponent lg={8} messageText={leagueTeamsError.errorMessage} />;


    if (!selectedLeagueID) return <MessageComponent lg={8} messageText={NO_LEAGUE_SELECTED_MESSAGE_TEXT} />;


    if (!leagueGames || !leagueTeams) return <SingleContentLoaderComponent lg={8} cssClasses={"start"} />;


    if (!leagueTeams?.length) return <MessageComponent lg={8} messageText={NO_TEAMS_MESSAGE_TEXT} cssClasses={"align-content-top"} />;
    if (!leagueGames?.length) return <MessageComponent lg={8} messageText={NO_GAMES_MESSAGE_TEXT} cssClasses={"align-content-top"} />;


    if (!selectedLeague) return null;


    /** Placeholder for League Status */
    const amateurLeagueStatus = true;


    const {leagueGroups: selectedLeagueGroups} = selectedLeague;

    let LeagueTableComponent: React.ReactNode = null;


    if (selectedLeagueGroups && selectedLeagueGroups.length > 1) {

        const GroupsComponent = [];

        for (let Group of selectedLeagueGroups) {

            const {league_group_name: groupName, league_group_teams_ids: groupTeamsIDs} = Group;

            if (!groupTeamsIDs) continue;

            const groupTeams = leagueTeams.filter((Team) => {
                const {team_id: teamID} = Team;
                return groupTeamsIDs.includes(teamID);
            });

            const groupTable = TableByLeagueGamesGenerator(groupTeams, leagueGames);

            if (!groupTable) continue;

            GroupsComponent.push(
                <Col xs={12} className={"leagues-tables-group"}>
                    <Row className={"leagues-tables-group-header"}>
                        <Col xs={12} className={"group-header"}>
                            <span>{groupName}</span>
                        </Col>
                    </Row>
                    <LeagueTableHeadComponent
                        amateurLeagueStatus={amateurLeagueStatus}
                    />
                    {groupTable.map((Team, i) =>
                        <LeagueTableTeamComponent
                            key={i}
                            team={Team}
                            teamPlace={i + 1}
                            amateurLeagueStatus={amateurLeagueStatus}
                        />
                    )}
                </Col>
            )

        }

        LeagueTableComponent = GroupsComponent;

    } else {
        const LeagueTable = TableByLeagueGamesGenerator(leagueTeams, leagueGames);
        if (LeagueTable) {
            LeagueTableComponent = LeagueTable.map((Team, i) =>
                <LeagueTableTeamComponent
                    key={i}
                    team={Team}
                    teamPlace={i + 1}
                    amateurLeagueStatus={amateurLeagueStatus}
                />
            );
        }
    }


    if (!LeagueTableComponent) return null;





    // const LeagueTableData = LeagueTable.map((Team, i) =>
    //     <LeagueTableTeamComponent
    //         key={i}
    //         team={Team}
    //         teamPlace={i + 1}
    //         amateurLeagueStatus={amateurLeagueStatus}
    //     />
    // );


    return (
        <Col xs={12} lg={8} className={"league-table-table" + (amateurLeagueStatus ? " amateur-league" : " professional-league")}>
            <Row className={"league-table-table-content"}>

                {!selectedLeagueGroups?.length &&
                <LeagueTableHeadComponent
                    amateurLeagueStatus={amateurLeagueStatus}
                />
                }

                {LeagueTableComponent}

            </Row>
        </Col>
    );

};


export default LeagueTableComponent;
