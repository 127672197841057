import LeagueTableTeamType from "@DataGenerators/Leagues/Tables/_Types/Teams/Team";
import {LeagueGameStatusType} from "@Interfaces/Api";


export const TeamTableDataFormatter = (
    team: LeagueTableTeamType,
    teamPointsIn: number, teamPointsOut: number, gameStatus: LeagueGameStatusType
) : boolean => {


    if (!team) return false;


    if (teamPointsIn > teamPointsOut) {
        team.teamWins += 1;
        team.teamPoints += 2;
    } else if (teamPointsOut > teamPointsIn) {
        team.teamLoses += 1
        if (gameStatus !== "FT_WAL") {
            team.teamPoints += 1;
        }
    }

    team.teamSmallPointsIn += teamPointsIn;
    team.teamSmallPointsOut += teamPointsOut;

    team.teamSmallPointsDiff = team.teamSmallPointsIn - team.teamSmallPointsOut;

    return true;

};


export default TeamTableDataFormatter;
