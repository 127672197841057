import React from "react";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


export interface QuarterEventTimeComponentProps {
    eventTime: string | null;
    cssClasses?: string | string[];
}


export const QuarterEventTimeComponent: React.FC<QuarterEventTimeComponentProps> = ({
    eventTime, cssClasses = ""
}) => {

    let reversedEventTime = "";

    if (eventTime) {

        const eventPreciseTime = eventTime.split(":");

        const eventMinute = parseInt(eventPreciseTime[0]);
        const eventSecond = parseInt(eventPreciseTime[1]);

        const reversedEventMinute = 9 - eventMinute;
        const reversedEventSecond = 60 - eventSecond;

        reversedEventTime += reversedEventMinute < 10 ? `0${reversedEventMinute}` : reversedEventMinute;
        reversedEventTime += ":";
        reversedEventTime += reversedEventSecond < 10 ? `0${reversedEventSecond}` : reversedEventSecond;

    }


    if (cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    return (
        <Col xs={4} lg={4} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__time" + (cssClasses ? ` ${cssClasses}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__time__content"}>
                <span className={"event-time"}>{reversedEventTime}</span>
            </Row>
        </Col>
    );


};


export default QuarterEventTimeComponent;
