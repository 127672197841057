import React from "react";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import TeamPlayersPhotoComponent from "@Components/Views/Pages/Games/Teams/Team/Players/Photo";
import TeamPlayersTop5Component from "@Components/Views/Pages/Games/Teams/Team/Players/Top";
import TeamPlayersListComponent from "@Components/Views/Pages/Games/Teams/Team/Players/List";


import "./index.scss";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import TeamPlayersTeamGamesComponent from "@TeamsPages/Team/Players/Games";


export interface TeamPlayersComponentProps {
    Team: LeagueTeamInterface;
}

export const TeamPlayersComponent: React.FC<TeamPlayersComponentProps> = ({
    Team
}) : JSX.Element | null => {


    if (!Team) return null;


    const {
        team_id: teamID, team_league: teamLeague,
        team_image: teamImage,
        team_players: teamPlayers,
        team_captain_id: teamCaptainID
    } = Team;


    return (
        <ContainerSectionComponent classes={"team-page__team-players"}>

            <TeamPlayersPhotoComponent
                photoURL={teamImage}
            />

            <TeamPlayersTop5Component
                teamID={teamID}
                teamLeague={teamLeague}
                photoURL={teamImage}
            />

            <TeamPlayersTeamGamesComponent
                teamID={teamID}
            />

            {!!teamPlayers &&
	        <TeamPlayersListComponent
		        teamPlayers={teamPlayers}
                teamCaptainID={teamCaptainID}
	        />
            }

        </ContainerSectionComponent>
    );

};


export default TeamPlayersComponent;
