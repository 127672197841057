import React from "react";


import { Col, Row } from "react-bootstrap";


import GameProgressQuarterEventComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


import "./index.scss";


export interface GameProgressQuarterEventsComponentProps {
    homeTeamID: number; awayTeamID: number;
    QuarterEvents: GameProgressEventType[];
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const GameProgressQuarterEventsComponent: React.FC<GameProgressQuarterEventsComponentProps> = ({
    homeTeamID, awayTeamID,
    QuarterEvents, GamePlayers
}) => {


    if (!QuarterEvents || !GamePlayers) {
        return null;
    }


    const QuarterEventsList = QuarterEvents.map((QuarterEvent, i) =>
        <GameProgressQuarterEventComponent
            key={i}
            eventOrderNr={i + 1}
            homeTeamID={homeTeamID}
            awayTeamID={awayTeamID}
            QuarterEvent={QuarterEvent}
            GamePlayers={GamePlayers}
        />
    );


    return (
        <Col className={"game-result-details__game-progress__quarters__quarter__events"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__content"}>

                {QuarterEventsList}

            </Row>
        </Col>
    )

};


export default GameProgressQuarterEventsComponent;
