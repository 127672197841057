import React from "react";


import { Col, Row } from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import PlayerProfileMainDataComponent from "@Pages/User/Authenticated/Profile/Player/Content/MainData";
import PlayerProfileDescriptionComponent from "@Pages/User/Authenticated/Profile/Player/Content/Description";
import UserFormalsAgreementsComponent from "@Pages/User/Authenticated/Profile/Formals";
import PlayerProfileTeamCaptainAreaComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea";


import { LeaguePlayerInterface } from "@Interfaces/Api";


import PlayerProfileContentTypeType from "@Pages/User/Authenticated/Profile/Player/_ContentType";


export interface PlayerProfileContentComponentProps {
    player: LeaguePlayerInterface | null;
    activeContent: PlayerProfileContentTypeType;
    updatePlayerTrigger: (update: boolean) => void;
}


export const PlayerProfileContentComponent: React.FC<PlayerProfileContentComponentProps> = ({
    player, activeContent, updatePlayerTrigger
}) : JSX.Element | null => {


    if (!player) return <SingleContentLoaderComponent lg={9} />;


    const {
        player_id: playerID,
        player_captained_teams: playerCaptainedTeams,
        player_regulations_acceptation_date: playerRegulationsAcceptationDate,
        player_privacy_acceptation_date: playerPrivacyAcceptationDate
    } = player;


    return (
        <Col xs={8} lg={{ span: 8, offset: 1 }} xl={{ span: 9, offset: 1 }} className={"user-profile-page-data"}>
            <Row className={"user-profile-page-data-content"}>

                {activeContent === "MAIN_DATA" &&
                <PlayerProfileMainDataComponent
                    Player={player}
                    updatePlayerTrigger={updatePlayerTrigger}
                />
                }

                {activeContent === "DESCRIPTION" &&
                <PlayerProfileDescriptionComponent
                    Player={player}
                    updatePlayerTrigger={updatePlayerTrigger}
                />
                }

                {activeContent === "FORMALS" &&
                <UserFormalsAgreementsComponent
                    userID={playerID}
                    userRegulationAcceptedDate={playerRegulationsAcceptationDate}
                    userPrivacyAcceptedDate={playerPrivacyAcceptationDate}
                />
                }

                {activeContent === "CAPTAIN" &&
                <PlayerProfileTeamCaptainAreaComponent
                    playerID={playerID}
                    captainedTeams={playerCaptainedTeams}
                    updatePlayerTrigger={updatePlayerTrigger}
                />
                }



            </Row>
        </Col>
    )

};


export default PlayerProfileContentComponent;
