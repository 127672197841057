export interface ROUTER_PAGE {
    ID: string;
    URL: string;
    TITLE: string;
}


export interface ROUTER_INTERFACE {
    PL: {
        COMMON: {
            HOME_PAGE: ROUTER_PAGE;
            ARTICLES_PAGE: ROUTER_PAGE;
            ARTICLE_PAGE: ROUTER_PAGE;
        },
        GAMES: {
            LEAGUES: {
                SEASONS: {
                    SEASONS: ROUTER_PAGE;
                    SEASON: ROUTER_PAGE;
                },
                LEAGUES: {
                    LEAGUES: ROUTER_PAGE;
                    LEAGUE: ROUTER_PAGE;
                },
                ROUNDS: {
                    ROUNDS: ROUTER_PAGE;
                    ROUND: ROUTER_PAGE;
                },
                GAMES: {
                    GAMES: ROUTER_PAGE;
                    GAME: ROUTER_PAGE;
                },
            },
            TEAMS: {
                TEAMS: ROUTER_PAGE;
                TEAM: ROUTER_PAGE;
            },
            PLAYERS: {
                PLAYERS: ROUTER_PAGE;
                PLAYER: ROUTER_PAGE;
            }
        }
    };
    EN: {
        COMMON: {
            HOME_PAGE: ROUTER_PAGE;
            ARTICLES_PAGE: ROUTER_PAGE;
            ARTICLE_PAGE: ROUTER_PAGE;
        },
        GAMES: {
            LEAGUES: {
                SEASONS: {
                    SEASONS: ROUTER_PAGE;
                    SEASON: ROUTER_PAGE;
                },
                LEAGUES: {
                    LEAGUES: ROUTER_PAGE;
                    LEAGUE: ROUTER_PAGE;
                },
                ROUNDS: {
                    ROUNDS: ROUTER_PAGE;
                    ROUND: ROUTER_PAGE;
                },
                GAMES: {
                    GAMES: ROUTER_PAGE;
                    GAME: ROUTER_PAGE;
                },
            },
            TEAMS: {
                TEAMS: ROUTER_PAGE;
                TEAM: ROUTER_PAGE;
            },
            PLAYERS: {
                PLAYERS: ROUTER_PAGE;
                PLAYER: ROUTER_PAGE;
            }
        }
    };
}


export const ROUTER: ROUTER_INTERFACE = {
    PL: {
        COMMON: {
            HOME_PAGE: {ID: "", TITLE: "", URL: ""},
            ARTICLES_PAGE: {ID: "articles-list-page", TITLE: "", URL: "/aktualnosci"},
            ARTICLE_PAGE: {ID: "article-page", TITLE: "", URL: "/aktualnosci"},
        },
        GAMES: {
            LEAGUES: {
                SEASONS: {
                    SEASONS: {ID: "", TITLE: "", URL: ""},
                    SEASON: {ID: "", TITLE: "", URL: ""},
                },
                LEAGUES: {
                    LEAGUES: {ID: "", TITLE: "", URL: ""},
                    LEAGUE: {ID: "", TITLE: "", URL: ""},
                },
                ROUNDS: {
                    ROUNDS: {ID: "", TITLE: "", URL: ""},
                    ROUND: {ID: "", TITLE: "", URL: ""},
                },
                GAMES: {
                    GAMES: {ID: "", TITLE: "", URL: ""},
                    GAME: {ID: "", TITLE: "", URL: ""},
                },
            },
            TEAMS: {
                TEAMS: {ID: "teams-list-page", TITLE: "Lista Zespołów", URL: "/druzyny"},
                TEAM: {ID: "single-team-page", TITLE: "Profil Zespołu", URL: "/druzyny"}
            },
            PLAYERS: {
                PLAYERS: {ID: "players-list-page", TITLE: "Lista Zawodników", URL: "/zawodnicy"},
                PLAYER: {ID: "single-player-page", TITLE: "Profil Zawodnika", URL: "/zawodnicy"}
            }
        }
    },
    EN: {
        COMMON: {
            HOME_PAGE: {ID: "", TITLE: "", URL: ""},
            ARTICLES_PAGE: {ID: "articles-list-page", TITLE: "", URL: "/aktualnosci"},
            ARTICLE_PAGE: {ID: "article-page", TITLE: "", URL: "/aktualnosci"},
        },
        GAMES: {
            LEAGUES: {
                SEASONS: {
                    SEASONS: {ID: "", TITLE: "", URL: ""},
                    SEASON: {ID: "", TITLE: "", URL: ""},
                },
                LEAGUES: {
                    LEAGUES: {ID: "", TITLE: "", URL: ""},
                    LEAGUE: {ID: "", TITLE: "", URL: ""},
                },
                ROUNDS: {
                    ROUNDS: {ID: "", TITLE: "", URL: ""},
                    ROUND: {ID: "", TITLE: "", URL: ""},
                },
                GAMES: {
                    GAMES: {ID: "", TITLE: "", URL: ""},
                    GAME: {ID: "", TITLE: "", URL: ""},
                },
            },
            TEAMS: {
                TEAMS: {ID: "teams-list-page", TITLE: "Teams List", URL: "/teams"},
                TEAM: {ID: "single-team-page", TITLE: "Team Profile", URL: "/teams"}
            },
            PLAYERS: {
                PLAYERS: {ID: "players-list-page", TITLE: "Players List", URL: "/zawodnicy"},
                PLAYER: {ID: "single-player-page", TITLE: "Player Profile", URL: "/zawodnicy"}
            }
        }
    }
};


export default ROUTER;
