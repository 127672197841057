import React, { useState } from "react";


import _ from "lodash";


import { Col, Row } from "react-bootstrap";


import RoundsSwitcherSelectedRoundComponent from "@ResultsPages/Switcher/Rounds/SelectedRound";
import RoundsSwitcherRoundComponent from "@ResultsPages/Switcher/Rounds/Round";


import {
    LeagueRoundInterface
} from "@Interfaces/Api";


import "./index.scss";


type ResultsPageRoundsSwitcherProps = {
    selectedLeagueID: number;
    selectedLeagueRoundID: number;
    selectedLeagueRoundHandler(roundNumber: number): void;
    LeagueRounds: LeagueRoundInterface[] | null;
};


export const ResultsPageRoundsSwitcherComponent: React.FC<ResultsPageRoundsSwitcherProps> = ({
    selectedLeagueID,
    LeagueRounds,
    selectedLeagueRoundID, selectedLeagueRoundHandler,
}) : JSX.Element | null => {


    const [leagueRoundsListOpenStatus, setLeagueRoundsListOpenStatus] = useState<boolean>(false);


    const changeSelectedLeagueRound = (leagueRoundID: number) => {

        if (leagueRoundID === -1) {
            return setLeagueRoundsListOpenStatus(!leagueRoundsListOpenStatus);
        }

        setLeagueRoundsListOpenStatus(false);
        selectedLeagueRoundHandler(leagueRoundID);

    };


    if (!selectedLeagueID || !LeagueRounds) {
        return null;
    }


    let SelectedLeagueRound: LeagueRoundInterface | null = null;

    if (selectedLeagueRoundID) {
        const SelectedRound = _.find(LeagueRounds, { "league_round_id": selectedLeagueRoundID })
        if (SelectedRound) {
            SelectedLeagueRound = SelectedRound;
        }
    }


    const LeagueRoundsList = LeagueRounds.map((LeagueRound, i) =>
        <RoundsSwitcherRoundComponent
            key={i}
            LeagueRound={LeagueRound}
            selectedLeagueRoundHandler={changeSelectedLeagueRound}
        />
    );

    if (LeagueRoundsList.length === 0) {
        return null;
    }


    return (
        <Col xs={6} lg={4} className={"rounds-switcher-component switcher-option-component leagues-schedules__leagues-and-rounds-switcher__rounds-switcher"}>
            <Row className={"rounds-switcher-component-content"}>

                <RoundsSwitcherSelectedRoundComponent
                    SelectedRound={SelectedLeagueRound}
                    selectedRoundIDChangeHandler={changeSelectedLeagueRound}
                />

                {leagueRoundsListOpenStatus && !!LeagueRoundsList.length &&
				<Col xs={12} className={"switcher-options-list leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__rounds-list"}>
                    {LeagueRoundsList}
				</Col>
                }

            </Row>
        </Col>
    );

};


export default ResultsPageRoundsSwitcherComponent;
