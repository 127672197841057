import React from "react";


import { UseStatisticsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


import TeamStatisticsInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams/Team/Statistics";


export interface GameTablesTeamTableStatsComponentProps {
    TeamStatistics: TeamStatisticsInterface;
}


export const GameTablesTeamTableStatsComponent: React.FC<GameTablesTeamTableStatsComponentProps> = ({
    TeamStatistics
}) : JSX.Element | null => {


    const TEAM_OR_TRAINER = UseStatisticsDictionaryPhrasesSet("TEAM_OR_TRAINER");

    const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");
    const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
    const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");


    if (!TeamStatistics) return null;


    const {
        T_O_REB: TEAM_OFFENSIVE_REBOUNDS,
        T_D_REB: TEAM_DEFENSIVE_REBOUNDS,
        T_T_REB: TEAM_REBOUNDS,
        T_TO: teamTurnovers,
        T_FOUL: teamFouls
    } = TeamStatistics;


    return (
        <Col xs={12} className={"game-tables-team-table-stats game-tables-team-table-stats-component game-tables-team-table-row"}>
            <Row className={"game-tables-team-table-stats-content game-tables-team-table-row-content"}>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-number"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col xs={7} lg={2} className={"table-summary-cell table-row-cell team-name"}>
                    <span className={"statistic-value"}>{TEAM_OR_TRAINER}</span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-points"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-minutes"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`}className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-throws pt1-percentage-throws"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col xs={1} lg={`auto`} title={OFFENSIVE_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-rebounds offensive-rebounds"}>
                    <span className={"statistic-value"}>{TEAM_OFFENSIVE_REBOUNDS}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={DEFENSIVE_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-rebounds"}>
                    <span className={"statistic-value"}>{TEAM_DEFENSIVE_REBOUNDS}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={TOTAL_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-rebounds"}>
                    <span className={"statistic-value"}>{TEAM_REBOUNDS}</span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-assists"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-summary-steals"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-summary-blocks"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col xs={1} lg={`auto`} title={TURNOVERS_TITLE} className={"table-summary-cell table-row-cell team-summary-turnovers"}>
                    <span className={"statistic-value"}>{teamTurnovers}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={FOULS_TITLE} className={"table-summary-cell table-row-cell team-summary-fouls"}>
                    <span className={"statistic-value"}>{teamFouls}</span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-summary-effectivity"}>
                    <span className={"statistic-value"}></span>
                </Col>

                <Col lg={`auto`} className={"table-summary-cell table-row-cell team-summary-eval"}>
                    <span className={"statistic-value"}></span>
                </Col>

            </Row>
        </Col>
        );

};


export default GameTablesTeamTableStatsComponent;
