import React, {useEffect, useRef, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {Container} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";

import DefaultPageHeaderComponent from "@Components/Elements/Content/Page/Header/Default";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import LeaguesTablesLeaguesComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues";


import "./index.scss";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";
import scrollToDomElement from "@Utilities/Scrolling";
import LeagueGroupInterface from "@Interfaces/Api/Leagues/Leagues/Group";
import {UseLeaguesList} from "@Services/Utilities/ApiHooks";
import MessageComponent from "@MessagesElements/Content";
import {UseErrorMessage, UseLeaguesDictionaryPhrasesSet} from "@Hooks/Texts";
import MappedLeaguesBySeasonsInterface from "@LeaguesPages/_Types/Leagues/BySeasons";
import _mapLeaguesBySeasons from "@LeaguesPages/_Utilities/MapLeaguesBySeasons";


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
    leagueActiveStatus: boolean;
    leagueOrderNr?: number;
    leagueGroups?: LeagueGroupInterface[] | null;
}

interface LeaguesSeasonInterface {
    [key: number]: {
        seasonID: number;
        seasonName: string | null;
        seasonLeagues: {
            [key: string]: SeasonLeagueInterface
        }
    }
}


export interface LeaguesTablesComponentProps {location: any}


export const LeaguesTablesComponent: React.FC<LeaguesTablesComponentProps> = ({location}) : JSX.Element | null => {


    const ComponentRef = useRef(null);


    const DEFAULT_DATA_REQUEST_ERROR = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const LEAGUES_TABLES_HEADER_TEXT = UseLeaguesDictionaryPhrasesSet("LEAGUES_TABLES_HEADER_TEXT");
    const NO_LEAGUES_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_LEAGUES_MESSAGE_TEXT");


    const [Leagues, setLeagues] = useState<LeagueInterface[] | null>(null);
    const [LeaguesError, setLeaguesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        /** Get Leagues in active Season */
        UseLeaguesList(
            setLeagues, setLeaguesError, false
        );

        /** Clear States after unmount */
        return () => {
            setLeagues(null);
            setLeaguesError(null);
        };

    }, []);


    useEffect(() => {
        if (!ComponentRef) return;
        /** Scroll to HTML element */
        scrollToDomElement(ComponentRef.current);
    }, []);


    if (LeaguesError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR} />;
    }


    if (!Leagues) {
        return <MainContentLoaderComponent />;
    }


    if (!Leagues.length) {
        return <MessageComponent messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;
    }


    const MappedLeaguesBySeasons = _mapLeaguesBySeasons(Leagues);


    const inheritedSelectedSeasonID = location?.state?.selectedSeason;
    const inheritedSelectedLeagueID = location?.state?.selectedLeague;


    return (
        <Container fluid={true} id={"leagues-tables-page-component"} className={"leagues-tables-page page-container-component"}>

            <DefaultPageHeaderComponent
                headerText={LEAGUES_TABLES_HEADER_TEXT}
            />

            <LeaguesTablesLeaguesComponent
                inheritedSelectedSeasonID={inheritedSelectedSeasonID}
                inheritedSelectedLeagueID={inheritedSelectedLeagueID}
                MappedLeaguesBySeasons={MappedLeaguesBySeasons}
            />

        </Container>
    );

};


export default LeaguesTablesComponent;


