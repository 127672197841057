import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import Dictionary from "@GlobalData/Localization/Dictionary";


import {Col, Row} from "react-bootstrap";


import PlayersListPlayerComponent from "@Components/Views/Pages/Games/Teams/Team/Players/List/Player";


import "./index.scss";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface TeamPlayersListComponentProps {
    teamPlayers: LeaguePlayerInterface[];
    teamCaptainID?: number | null;
}


export const TeamPlayersListComponent: React.FC<TeamPlayersListComponentProps> = ({
    teamPlayers, teamCaptainID
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {word_players_text: WORD_PLAYERS_TEXT} = Dictionary[activeLanguage];


    if (!teamPlayers) return null;


    const Players = teamPlayers.map((Player, i) =>
        <PlayersListPlayerComponent key={i} Player={Player} teamCaptainID={teamCaptainID} />
    );


    return (
        <Col xs={12} className={"team-page__team-players__players-list"}>
            <Row className={"team-players-list-content"}>

                <Col xs={12} className={"players-list-header"}>
                    <span>{WORD_PLAYERS_TEXT}</span>
                </Col>

                {Players}

            </Row>
        </Col>
    );

};


export default TeamPlayersListComponent;
