import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";


import "./index.scss";
import {LeagueGameInterface} from "@Interfaces/Api";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import {TeamNextGamesListComponent} from "@TeamsPages/Team/Players/Games/List";


export interface TeamPlayersTeamGamesComponentProps {
    teamID: number;
}


export const TeamPlayersTeamGamesComponent: React.FC<TeamPlayersTeamGamesComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    if (!teamID) {
        return null;
    }

    return (
        <Col xs={12} lg={4} className={"team-page__team-players__team-games"}>
            <Row className={"team-page__team-players__team-games__content"}>

                <Col xs={12} className={"team-page__team-players__team-games__header"}>
                    <span>{`Nadchodzące mecze`}</span>
                </Col>

                <TeamNextGamesListComponent
                    teamID={teamID}
                />

            </Row>
        </Col>
    )


};


export default TeamPlayersTeamGamesComponent;
