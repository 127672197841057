import React from "react";

import { UseRoute } from "@Hooks/Routes";


import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayersPlayerComponentProps {
    Player: LeaguePlayerInterface;
}


export const PlayersPlayerComponent: React.FC<PlayersPlayerComponentProps> = ({
    Player
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    const GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT = UsePlayersDictionaryPhrasesSet("GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT");


    if (!Player) return null;


    const {
        player_id: playerID, player_slug: playerSlug,
        player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
        player_pseudonymization_status: playerPseudonymizationStatus
    } = Player;


    const playerPageServiceURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;

    const playerFullName = playerPseudonymizationStatus ? `"${playerNickName}"` : `${playerFirstName} ${playerLastName}`;


    return (
        <Col key={playerID} xs={12} className={"players-list-player"}>
            <NavLink to={playerPageServiceURL} title={GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT} className={"player-name"}>
                <span>{playerFullName}</span>
            </NavLink>
        </Col>
    );

};


export default PlayersPlayerComponent;
