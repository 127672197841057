import React, {useEffect, useState} from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import PlayerDetailsSectionSwitcherComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/_Switcher";



import PlayerDetailsStatisticsComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/Statistics";
import PlayerDetailsPlayedGamesComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/PlayedGames";
import PlayerDetailsBestScoresComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores";


import sortPlayerGamesByLeagues from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Utilities/Games/SortBySeasons";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import LeagueGamePlayerInterface from "@Interfaces/Api/Leagues/Games/Game/Player";
import PlayerDetailsSectionsTypes from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Sections";


interface PlayersDetailsDataComponentProps {
    playerID: number | null;
}


export const PlayersDetailsDataComponent: React.FC<PlayersDetailsDataComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    const [selectedSection, setSelectedSection] = useState<PlayerDetailsSectionsTypes>(PlayerDetailsSectionsTypes.PLAYER_STATISTICS);

    useEffect(() => {
        return () => {
            setSelectedSection(PlayerDetailsSectionsTypes.PLAYER_STATISTICS);
        };
    }, []);


    const [playersDetailsData, setPlayersDetailsData] = useState<LeagueGamePlayerInterface[] | null>(null);
    const [playersDetailsDataError, setPlayersDetailsDataError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!playerID) return;

        const PLAYER_DETAILS_API_URL: string = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL + "/leagues/games/teams/players";

        const getPlayersDetailsData = async () => {

            try {

                const RESPONSE = await REST_API_REQUEST(`${PLAYER_DETAILS_API_URL}/?league_game_team_player_player=${playerID}&all_results=true`);

                if (RESPONSE.ok) {
                    const RESPONSE_CONTENT = await RESPONSE.json();
                    setPlayersDetailsData(RESPONSE_CONTENT);
                } else {
                    setPlayersDetailsDataError({errorCode: RESPONSE.status, errorMessage: ""});
                }

            } catch (e) {
                setPlayersDetailsDataError({errorCode: 500, errorMessage: ""});
            }

        };

        getPlayersDetailsData();

    }, []);

    useEffect(() => {
        return () => {
            setPlayersDetailsData(null);
            setPlayersDetailsDataError(null);
        };
    }, []);

    if (!playerID) return null;


    if (!!playersDetailsDataError) return null;


    const PlayerGames = sortPlayerGamesByLeagues(playersDetailsData);


    return (
        <ContainerSectionComponent id={"player-details-data"} classes={"player-details-data player-details-data-component"}>

            <PlayerDetailsSectionSwitcherComponent
                selectedSection={selectedSection}
                selectedSectionHandler={setSelectedSection}
            />

            {selectedSection === PlayerDetailsSectionsTypes.PLAYER_STATISTICS &&
	        <PlayerDetailsStatisticsComponent playerGames={PlayerGames}/>
            }

            {selectedSection === PlayerDetailsSectionsTypes.PLAYER_PLAYED_GAMES &&
	        <PlayerDetailsPlayedGamesComponent playerGames={PlayerGames}/>
            }

            {selectedSection === PlayerDetailsSectionsTypes.PLAYER_BEST_SCORES &&
	        <PlayerDetailsBestScoresComponent playerGames={PlayerGames}/>
            }

        </ContainerSectionComponent>
    );


};


export default PlayersDetailsDataComponent;
