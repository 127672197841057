export * from "@Dictionary/Sets/Common/Types/Phrases/Set";
export * from "@Dictionary/Sets/Common/Phrases";


import CommonDictionaryInterface from "@Dictionary/Sets/Common/Types";

import {
    SELECT_ALL_MAN_PL, SELECT_ALL_MAN_EN, UN_SELECT_ALL_MAN_PL, UN_SELECT_ALL_MAN_EN,
    I_UNDERSTAND_PHRASE_PL, I_UNDERSTAND_PHRASE_EN,
    LACK_WORD_PL, LACK_WORD_EN, ADD_WORD_PL, ADD_WORD_EN, ABORT_WORD_PL, ABORT_WORD_EN,
    DOWNLOAD_WORD_PL, DOWNLOAD_WORD_EN,
    DOCUMENT_NAME_TEXT_PL, DOCUMENT_NAME_TEXT_EN, ADD_NEW_DOCUMENT_TEXT_PL, ADD_NEW_DOCUMENT_TEXT_EN,
    DEALS_AND_DOCUMENTS_TEXT_PL, DEALS_AND_DOCUMENTS_TEXT_EN,
    NO_FILE_TO_UPLOAD_TEXT_PL, NO_FILE_TO_UPLOAD_TEXT_EN,
    FILE_UPLOAD_SUCCESS_TEXT_PL, FILE_UPLOAD_SUCCESS_TEXT_EN,
    FILE_UPLOAD_ERROR_TEXT_PL, FILE_UPLOAD_ERROR_TEXT_EN,
    TIME_WORD_PL, TIME_WORD_EN, START_WORD_PL, START_WORD_EN, FINISH_WORD_PL, FINISH_WORD_EN,
    GAME_WORD_PL, GAME_WORD_EN, GAME_WORD_GENITIVE_PL, GAME_WORD_GENITIVE_EN, GAME_START_PHRASE_PL, GAME_START_PHRASE_EN, GAME_FINISH_PHRASE_PL, GAME_FINISH_PHRASE_EN,
} from "@Dictionary/Sets/Common/Phrases";



export const CommonDictionary: CommonDictionaryInterface = {
    PL: {
        SELECT_ALL_MAN: SELECT_ALL_MAN_PL, UN_SELECT_ALL_MAN: UN_SELECT_ALL_MAN_PL,
        I_UNDERSTAND_PHRASE: I_UNDERSTAND_PHRASE_PL,
        LACK_WORD: LACK_WORD_PL, ADD_WORD: ADD_WORD_PL, ABORT_WORD: ABORT_WORD_PL,
        DOWNLOAD_WORD: DOWNLOAD_WORD_PL,
        DOCUMENT_NAME_TEXT: DOCUMENT_NAME_TEXT_PL, ADD_NEW_DOCUMENT_TEXT: ADD_NEW_DOCUMENT_TEXT_PL,
        DEALS_AND_DOCUMENTS_TEXT: DEALS_AND_DOCUMENTS_TEXT_PL,
        NO_FILE_TO_UPLOAD_TEXT: NO_FILE_TO_UPLOAD_TEXT_PL, FILE_UPLOAD_SUCCESS_TEXT: FILE_UPLOAD_SUCCESS_TEXT_PL, FILE_UPLOAD_ERROR_TEXT: FILE_UPLOAD_ERROR_TEXT_PL,
        TIME_WORD: TIME_WORD_PL, START_WORD: START_WORD_PL, FINISH_WORD: FINISH_WORD_PL,
        GAME_WORD: GAME_WORD_PL, GAME_WORD_GENITIVE: GAME_WORD_GENITIVE_PL, GAME_START_PHRASE: GAME_START_PHRASE_PL, GAME_FINISH_PHRASE: GAME_FINISH_PHRASE_PL,
    },
    EN: {
        SELECT_ALL_MAN: SELECT_ALL_MAN_EN, UN_SELECT_ALL_MAN: UN_SELECT_ALL_MAN_EN,
        I_UNDERSTAND_PHRASE: I_UNDERSTAND_PHRASE_EN,
        LACK_WORD: LACK_WORD_EN, ADD_WORD: ADD_WORD_EN, ABORT_WORD: ABORT_WORD_EN,
        DOWNLOAD_WORD: DOWNLOAD_WORD_EN,
        DOCUMENT_NAME_TEXT: DOCUMENT_NAME_TEXT_EN, ADD_NEW_DOCUMENT_TEXT: ADD_NEW_DOCUMENT_TEXT_EN,
        DEALS_AND_DOCUMENTS_TEXT: DEALS_AND_DOCUMENTS_TEXT_EN,
        NO_FILE_TO_UPLOAD_TEXT: NO_FILE_TO_UPLOAD_TEXT_EN, FILE_UPLOAD_SUCCESS_TEXT: FILE_UPLOAD_SUCCESS_TEXT_EN, FILE_UPLOAD_ERROR_TEXT: FILE_UPLOAD_ERROR_TEXT_EN,
        TIME_WORD: TIME_WORD_EN, START_WORD: START_WORD_EN, FINISH_WORD: FINISH_WORD_EN,
        GAME_WORD: GAME_WORD_EN, GAME_WORD_GENITIVE: GAME_WORD_GENITIVE_EN, GAME_START_PHRASE: GAME_START_PHRASE_EN, GAME_FINISH_PHRASE: GAME_FINISH_PHRASE_EN,
    }
};


export default CommonDictionary;
