import React from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";


import { UseRoute } from "@Hooks/Routes";


import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts";


import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";


import { NO_AVATAR_IMAGE } from "@Images";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayersListPlayerComponentProps {
    Player: LeaguePlayerInterface;
    teamCaptainID?: number | null;
}


export const PlayersListPlayerComponent: React.FC<PlayersListPlayerComponentProps> = ({
    Player, teamCaptainID
}) : JSX.Element | null => {


    const API_URL = REST_API_ENDPOINTS_URLS.API_ROOT_URL;

    const PlayersRoute = UseRoute("PLAYERS");


    const GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT = UsePlayersDictionaryPhrasesSet("GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT");


    if (!Player) return null;


    const {
        player_id: playerID, player_slug: playerSlug,
        player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
        player_profile_image: playerAvatar,
        player_number: playerNumber,
        player_pseudonymization_status: playerPseudonymizationStatus
    } = Player;


    const playerServiceURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;

    const playerFullName = playerPseudonymizationStatus ? `"${playerNickName}"` : `${playerFirstName} ${playerLastName}`


    return (
        <Col xs={4} lg={2} className={"team-player"}>
            <Row className={"team-player-content"}>

                <Col xs={12} className={"player-avatar"}>
                    <NavLink to={playerServiceURL} title={GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT}>
                        {!!playerAvatar && <img src={playerAvatar} alt={``} />}
                    </NavLink>
                </Col>

                <Col xs={12} className={"player-name"}>
                    <NavLink to={`/zawodnicy/${playerSlug}`} title={GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT}>
                        <span>{playerFullName}</span>
                        {teamCaptainID === playerID && <span className={"player-captain-status"}>(C)</span>}
                        {playerNumber && <span className={"player-number"}>#{playerNumber}</span>}
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayersListPlayerComponent;
