import React from "react";


import { Col, Row } from "react-bootstrap";


import UseLeagueRoundLabel from "@Texts/Games/Schedule/Rounds/_Hook";


import { LeagueRoundInterface } from "@Interfaces/Api";


export interface RoundsSwitcherRoundComponentProps {
    LeagueRound: LeagueRoundInterface;
    selectedLeagueRoundHandler: (leagueRoundID: number) => void;
}


export const RoundsSwitcherRoundComponent: React.FC<RoundsSwitcherRoundComponentProps> = ({
    LeagueRound, selectedLeagueRoundHandler
}) => {


    if (!LeagueRound) {
        return null;
    }


    const changeSelectedRoundHandler = (e: React.MouseEvent, leagueRoundID: number) => {
        e.preventDefault();
        selectedLeagueRoundHandler(leagueRoundID);
    }


    const {
        league_round_id: leagueRoundID,
        league_round_type: leagueRoundType,
        league_round_number: leagueRoundNumber, league_round_name: leagueRoundName,
    } = LeagueRound


    let leagueRoundLabel = "";

    if (leagueRoundName) {
        leagueRoundLabel = leagueRoundName;
    } else if (leagueRoundType) {
        leagueRoundLabel = UseLeagueRoundLabel(leagueRoundType);
        if (leagueRoundType === 1) {
            leagueRoundLabel = `${leagueRoundNumber} ${leagueRoundLabel}`;
        }
    }


    return (
        <Row onClick={(e: React.MouseEvent) => changeSelectedRoundHandler(e, leagueRoundID)} className={"options-list-option"}>

            <Col xs={12} className={"options-list-option-name"}>
                <a href={`#round-${leagueRoundID}-results-list`}>{leagueRoundLabel}</a>
            </Col>

        </Row>
    );

};


export default RoundsSwitcherRoundComponent;
