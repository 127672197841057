import React, { useEffect, useState } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import { Container, Row } from "react-bootstrap";


import HeadSponsorComponent from "@Parts/Results/HeadSponsor";
import ResultsBarListComponent from "@Parts/Results/List";


import { PartnerInterface } from "@Interfaces/Api";


import "./index.scss";


type ApplicationResultsBar = {};


export const ApplicationResultsBarComponent: React.FC<ApplicationResultsBar> = () : JSX.Element | null => {


    const [HeadSponsors, setHeadSponsors] = useState<PartnerInterface[]>([]);

    useEffect(() => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;
        const SPONSORS_URL = `${API_URL}/settings/sponsors/?sponsor_promoted_status=1&results_limit=2`;

        const getHeadSponsors = async () => {
            try {
                const response = await REST_API_REQUEST(SPONSORS_URL);
                if (response.ok) {
                    const responseContent = await response.json();
                    setHeadSponsors([...responseContent]);
                }
            } catch (e) {}
        };

        getHeadSponsors();

    }, []);

    useEffect(() => {
        return () => {
            setHeadSponsors([]);
        };
    }, []);

    return (
        <Container fluid={true} className={"results-bar-component results-bar main-section-component"}>
            <Container className={"main-section-container"}>
                <Row className={"results-bar-content"}>

                    <HeadSponsorComponent Sponsor={HeadSponsors[0]} bannerLocation={"LEFT"} />

                    <ResultsBarListComponent xs={12} />

                    <HeadSponsorComponent Sponsor={HeadSponsors[1]} bannerLocation={"RIGHT"} />

                </Row>
            </Container>
        </Container>
    );
};


export default ApplicationResultsBarComponent;
