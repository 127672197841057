import React from "react";


import { Col } from "react-bootstrap";


import {
    LeagueGameInterface
} from "@Interfaces/Api";


import ResultsListRoundGameComponent from "@ResultsPages/Rounds/Round/Games/Game";


import "./index.scss";


export interface ResultsListRoundGamesComponentProps {
    LeagueRoundGames?: LeagueGameInterface[];
}


export const ResultsListRoundGamesComponent: React.FC<ResultsListRoundGamesComponentProps> = ({
    LeagueRoundGames
}) => {


    if (!LeagueRoundGames) {
        return null;
    }


    const LeagueRoundGamesList = LeagueRoundGames.sort(
        (game1, game2) => new Date(game1["league_game_date"] || "") > new Date(game2["league_game_date"] || "") ? 1 : -1
    ).map((LeagueRoundGame, i) =>
        <ResultsListRoundGameComponent key={i} LeagueGame={LeagueRoundGame} />
    );


    return (
        <Col xs={12} lg={{ span: 10 }} className={"results-list-games-list leagues-schedules__league-rounds-list__league_round__games-list"}>
            {LeagueRoundGamesList}
        </Col>
    );


};


export default ResultsListRoundGamesComponent;
