import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import Dictionary from "@GlobalData/Localization/Dictionary";


import {Col, Row} from "react-bootstrap";


import StatisticsType from "@GlobalData/Statistics/Types/Type";


export interface PlayersTop5HeaderComponentProps {
    selectedStatisticsType: StatisticsType;
    selectedStatisticsTypeHandler: (type: StatisticsType) => void;
}


export const PlayersTop5HeaderComponent: React.FC<PlayersTop5HeaderComponentProps> = ({
    selectedStatisticsType, selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        statistics_points_label_text: STATISTICS_POINTS_LABEL_TEXT,
        statistics_eval_label_text: STATISTICS_EVAL_LABEL_TEXT,
        statistics_assists_label_text: STATISTICS_AST_LABEL_TEXT,
        statistics_rebounds_label_text: STATISTICS_REB_LABEL_TEXT,
        statistics_steals_label_text: STATISTICS_STL_LABEL_TEXT,
        statistics_blocks_label_text: STATISTICS_BLK_LABEL_TEXT,
    } = Dictionary[activeLanguage];


    return (
        <Col xs={3} lg={12} className={"team-players-top5-header team-players-top5-header-component"}>
            <Row className={"team-players-top5-header-content"}>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "TPTS" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("TPTS")}>{STATISTICS_POINTS_LABEL_TEXT}</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "EVAL" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("EVAL")}>{STATISTICS_EVAL_LABEL_TEXT}</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "AST" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("AST")}>{STATISTICS_AST_LABEL_TEXT}</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "TREB" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("TREB")}>{STATISTICS_REB_LABEL_TEXT}</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "STL" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("STL")}>{STATISTICS_STL_LABEL_TEXT}</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"players-top5-header-stat" + (selectedStatisticsType === "BLK" ? " selected" : "")}>
                    <span onClick={() => selectedStatisticsTypeHandler("BLK")}>{STATISTICS_BLK_LABEL_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayersTop5HeaderComponent;
