import React, {useState, useEffect} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {Redirect} from "react-router-dom";


import {Container} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";
import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";

import HttpResponseErrorMessages from "@GlobalData/Messages/HTTP/Response/Errors";
import SystemErrorMessages from "@GlobalData/Messages/System/Errors";


import TeamHeaderComponent from "@Components/Views/Pages/Games/Teams/Team/Header";
import TeamStatisticsComponent from "@Components/Views/Pages/Games/Teams/Team/Statistics";
import TeamPlayersComponent from "@Components/Views/Pages/Games/Teams/Team/Players";


import "./index.scss";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface TeamPageComponentProps {
    match: any;
}


export const TeamPageComponent: React.FC<TeamPageComponentProps> = ({
    match
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();


    const TEAM_SLUG = match?.params?.teamSlug;
    const TEAM_API_URL = REST_API_ENDPOINTS_URLS.GAMES.TEAMS.TEAM.BY_SLUG_URL;


    const [Team, setTeam] = useState<LeagueTeamInterface | null>(null);
    const [TeamError, setTeamError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!TEAM_SLUG || !TEAM_API_URL) return;

        const getTeam = async () => {

            try {

                const response = await REST_API_REQUEST(`${TEAM_API_URL}/?team_slug=${TEAM_SLUG}`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setTeam(responseContent);
                } else {
                    setTeamError({errorCode: response.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setTeamError({errorCode: 501, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

        };

        getTeam();

    }, [TEAM_SLUG, TEAM_API_URL]);

    useEffect(() => {
        return () => {
            setTeam(null);
            setTeamError(null);
        };
    }, []);


    if (!TEAM_SLUG || !TEAM_API_URL) return <Redirect to={"/druzyny"} />;


    if (!!TeamError) return <ErrorMessageComponent messageText={TeamError.errorMessage} />;


    if (!Team) return <MainContentLoaderComponent />;


    const {team_id: teamID} = Team;

    return (
        <Container fluid={true} id={"team-page"} className={"team-page team-page-component"}>

            <TeamHeaderComponent
                Team={Team}
            />

	        <TeamStatisticsComponent
		        teamID={teamID}
	        />

            <TeamPlayersComponent
                Team={Team}
            />

        </Container>
        );

};


export default TeamPageComponent;
