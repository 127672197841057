import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";

// import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions/";


import scrollToDomElement from "@Utilities/Scrolling";


import PageContainerComponent from "@UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@UI/Containers/ContentContainer";


import ContactPageTextComponent from "@CommonPages/ContactPage/Text";
import ContactPageContentComponent from "@CommonPages/ContactPage/Content";


export const ContactPageComponent = () => {

	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 250);
	}, []);
	
	
	const [contactFormSentStatus, setContactFormSentStatus] = useState(false);
	
	
	const SEND_CONTACT_FORM_MESSAGE = (formData) => {
		
		const {
			// messageSenderName, messageSenderFunction,
			// messageSenderPhone, messageSenderEmail,
			// messageContentValue
		} = formData;
		
		// DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		setContactFormSentStatus(true);
		
		// DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"contact-page-component"}>
			
			<PageHeaderContainerComponent headerText={"Kontakt"} />
			
			<ContactPageTextComponent />
			
			<PageContentContainerComponent cssClasses={"contact-page-content"}>
				<ContactPageContentComponent
					formSentStatus={contactFormSentStatus}
					sendMessageHandler={SEND_CONTACT_FORM_MESSAGE}
				/>
			</PageContentContainerComponent>
			
		</PageContainerComponent>
	);
	
};


export default ContactPageComponent;
