import UseLanguage from "@LocalizationStates/Language";


import InformationMessages from "@Dictionary/Messages/Info";
import InformationMessageType from "@Dictionary/Messages/Info/Messages/Message";


export const UseInformationMessage = (messageType: InformationMessageType) => {

    const ActiveLanguage = UseLanguage();

    return InformationMessages[ActiveLanguage][messageType];

};


export default UseInformationMessage;
