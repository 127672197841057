import React from "react";


import { UseStatisticsDictionaryPhrasesSet } from "@Hooks/Texts";


import { formatMinutesFromSeconds } from "@Services/Utilities/Tools/Formatters";

import {
    countPercentageThrowsEffectivity, countTotalPoints, countEval
} from "@Services/Utilities/Games";


import { Col, Row } from "react-bootstrap";


import NumberFormat from "react-number-format";


import "./index.scss";


import TeamSummaryStatisticsInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams/Team/SummaryStatistics";
import TeamStatisticsInterface from "@GameResultPageTypes/Summary/GUI/_Main/Teams/Team/Statistics";



export interface GameTablesTeamTableSummaryComponentProps {
    TeamSummaryStatistics: TeamSummaryStatisticsInterface;
    TeamStatistics: TeamStatisticsInterface;
}


export const GameTablesTeamTableSummaryComponent: React.FC<GameTablesTeamTableSummaryComponentProps> = ({
    TeamSummaryStatistics, TeamStatistics
}) : JSX.Element | null => {


    const TOTAL = UseStatisticsDictionaryPhrasesSet("TOTAL");

    const POINTS_TITLE = UseStatisticsDictionaryPhrasesSet("POINTS_TITLE");

    const MINUTES_TITLE = UseStatisticsDictionaryPhrasesSet("MINUTES_TITLE");

    const THROWS_1PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_TITLE");
    const THROWS_1PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_TITLE");

    const THROWS_2PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_TITLE");
    const THROWS_2PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_TITLE");

    const THROWS_3PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_TITLE");
    const THROWS_3PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_TITLE");

    const THROWS_GAME_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_TITLE");
    const THROWS_GAME_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_TITLE");

    const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");

    const ASSISTS_TITLE = UseStatisticsDictionaryPhrasesSet("ASSISTS_TITLE");
    const BLOCKS_TITLE = UseStatisticsDictionaryPhrasesSet("BLOCKS_TITLE");
    const STEALS_TITLE = UseStatisticsDictionaryPhrasesSet("STEALS_TITLE");

    const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
    const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");

    const EFFECTIVITY_TITLE = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_TITLE");
    const EVAL_TITLE = UseStatisticsDictionaryPhrasesSet("EVAL_TITLE");


    if (!TeamSummaryStatistics || !TeamStatistics) return null;


    const {
        PT1: teamAccurate1PtThrows, PT1A: teamNotAccurate1PtThrows,
        PT2: teamAccurate2PtThrows, PT2A: teamNotAccurate2PtThrows,
        PT3: teamAccurate3PtThrows, PT3A: teamNotAccurate3PtThrows,
        AST: teamAssists, BLK: teamBlocks, STL: teamSteals,
        O_REB: teamOffensiveRebounds, D_REB: teamDefensiveRebounds, T_REB: teamRebounds,
        FOUL: teamFouls, FOUL_TECH: teamFoulsTech, FOUL_NS: teamFoulsUns, FOUL_DSQ: teamFoulsDSQ, TEAM_FOUL: teamTeamFouls,
        TO: teamTurnovers,
        SECONDS: teamSeconds, EFF: teamEffectivity
    } = TeamSummaryStatistics;

    const {
        T_TO: teamTO, T_O_REB, T_D_REB, T_T_REB, T_FOUL
    } = TeamStatistics;

    // const teamTotalTurnovers = teamTurnovers + teamTO;
    const teamTotalTurnovers = teamTurnovers;

    const teamMinutes = formatMinutesFromSeconds(teamSeconds);

    const teamAllPt1Throws = teamAccurate1PtThrows + teamNotAccurate1PtThrows;
    const teamAllPt2Throws = teamAccurate2PtThrows + teamNotAccurate2PtThrows;
    const teamAllPt3Throws = teamAccurate3PtThrows + teamNotAccurate3PtThrows;

    const teamPt1ThrowsEffectivity = countPercentageThrowsEffectivity(teamAccurate1PtThrows, teamAllPt1Throws);
    const teamPt2ThrowsEffectivity = countPercentageThrowsEffectivity(teamAccurate2PtThrows, teamAllPt2Throws);
    const teamPt3ThrowsEffectivity = countPercentageThrowsEffectivity(teamAccurate3PtThrows, teamAllPt3Throws);

    const teamAccurateGameThrows = teamAccurate2PtThrows + teamAccurate3PtThrows;
    const teamAllGameThrows = teamAllPt2Throws + teamAllPt3Throws;
    const teamGameThrowsEffectivity = countPercentageThrowsEffectivity(teamAccurateGameThrows, teamAllGameThrows);

    const teamTotalPoints = countTotalPoints(
        teamAccurate1PtThrows, teamAccurate2PtThrows, teamAccurate3PtThrows
    );

    const teamPlayersRebounds = teamOffensiveRebounds + teamDefensiveRebounds;
    // const teamTotalRebounds = teamPlayersRebounds + teamRebounds;
    const teamTotalRebounds = teamPlayersRebounds;

    const teamEval = countEval(
        teamTotalPoints,
        teamPlayersRebounds,
        teamAssists,
        teamSteals,
        teamBlocks,
        teamTurnovers,
        teamAllPt2Throws,
        teamAllPt3Throws,
        teamAccurate2PtThrows,
        teamAccurate3PtThrows,
        teamAllPt1Throws,
        teamAccurate1PtThrows
    );


    return (
        <Col xs={12} className={"game-tables-team-table-summary game-tables-team-table-summary-component game-tables-team-table-row"}>
            <Row className={"game-tables-team-table-summary-content game-tables-team-table-row-content"}>

                <Col xs={`auto`} className={"table-summary-cell table-row-cell team-summary-number"}></Col>

                <Col xs={6} lg={2} className={"table-summary-cell table-row-cell team-summary-name"}>
                    <span className={"statistic-value"}>{TOTAL}</span>
                </Col>

                <Col xs={3} lg={`auto`} title={POINTS_TITLE} className={"table-summary-cell table-row-cell team-summary-points"}>
                    <span className={"statistic-value"}>{teamTotalPoints}</span>
                </Col>

                <Col xs={3} lg={`auto`} title={MINUTES_TITLE} className={"table-summary-cell table-row-cell team-summary-minutes"}>
                    <span className={"statistic-value"}>{teamMinutes}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_2PT_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-2pt-throws"}>
                    <span className={"statistic-value"}>{teamAccurate2PtThrows}/{teamAllPt2Throws}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_2PT_EFF_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-2pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        <NumberFormat value={teamPt2ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span>%</span>
                    </span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_3PT_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-3pt-throws"}>
                    <span className={"statistic-value"}>{teamAccurate3PtThrows}/{teamAllPt3Throws}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_3PT_EFF_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-3pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        <NumberFormat value={teamPt3ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span>%</span>
                    </span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_GAME_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-game-throws"}>
                    <span className={"statistic-value"}>{teamAccurateGameThrows}/{teamAllGameThrows}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_GAME_EFF_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-game-throws-eff"}>
                    <span className={"statistic-value"}>
                    <NumberFormat value={teamGameThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span>%</span>
                    </span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_1PT_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-1pt-throws"}>
                    <span className={"statistic-value"}>{teamAccurate1PtThrows}/{teamAllPt1Throws}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={THROWS_1PT_EFF_TITLE} className={"table-summary-cell table-row-cell team-summary-throws team-summary-1pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        <NumberFormat value={teamPt1ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span>%</span>
                    </span>
                </Col>

                <Col xs={1} lg={`auto`} title={OFFENSIVE_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-summary-rebounds"}>
                    <span className={"statistic-value"}>{teamOffensiveRebounds}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={DEFENSIVE_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-summary-rebounds"}>
                    <span className={"statistic-value"}>{teamDefensiveRebounds}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={TOTAL_REBOUNDS_TITLE} className={"table-summary-cell table-row-cell team-summary-rebounds"}>
                    <span className={"statistic-value"}>{teamTotalRebounds}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={ASSISTS_TITLE} className={"table-summary-cell table-row-cell team-summary-assists"}>
                    <span className={"statistic-value"}>{teamAssists}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={STEALS_TITLE} className={"table-summary-cell table-row-cell team-summary-steals"}>
                    <span className={"statistic-value"}>{teamSteals}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={BLOCKS_TITLE} className={"table-summary-cell table-row-cell team-summary-blocks"}>
                    <span className={"statistic-value"}>{teamBlocks}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={TURNOVERS_TITLE} className={"table-summary-cell table-row-cell team-summary-turnovers"}>
                    <span className={"statistic-value"}>{teamTotalTurnovers}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={FOULS_TITLE} className={"table-summary-cell table-row-cell team-summary-fouls"}>
                    <span className={"statistic-value"}>{teamFouls + teamFoulsTech + teamFoulsUns + teamFoulsDSQ + teamTeamFouls}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={EFFECTIVITY_TITLE} className={"table-summary-cell table-row-cell team-summary-effectivity"}>
                    <span className={"statistic-value"}>{teamEffectivity}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={EVAL_TITLE} className={"table-summary-cell table-row-cell team-summary-eval"}>
                    <span className={"statistic-value"}>{teamEval}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameTablesTeamTableSummaryComponent;
