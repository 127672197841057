import React from "react";


import { UseLeaguesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


export interface LeagueTableHeadComponentProps {
    amateurLeagueStatus: boolean;
}


export const LeagueTableHeadComponent: React.FC<LeagueTableHeadComponentProps> = ({
    amateurLeagueStatus = true
}) : JSX.Element | null => {


    const TABLE_PLACE_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_PLACE_LABEL");
    const TABLE_TEAM_TITLE = UseLeaguesDictionaryPhrasesSet("TABLE_TEAM_TITLE");
    const TABLE_POINTS_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_POINTS_SHORT_LABEL");
    const TABLE_POINTS_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_POINTS_LABEL");
    const TABLE_GAMES_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_GAMES_SHORT_LABEL");
    const TABLE_GAMES_TITLE = UseLeaguesDictionaryPhrasesSet("TABLE_GAMES_TITLE");
    const TABLE_VICTORIES_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_VICTORIES_SHORT_LABEL");
    const TABLE_VICTORIES_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_VICTORIES_LABEL");
    const TABLE_DEFEATS_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_DEFEATS_SHORT_LABEL");
    const TABLE_DEFEATS_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_DEFEATS_LABEL");
    const TABLE_TEAM_SMALL_POINTS_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_TEAM_SMALL_POINTS_SHORT_LABEL");
    const TABLE_TEAM_SMALL_POINTS_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_TEAM_SMALL_POINTS_LABEL");
    const TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL");
    const TABLE_OPPONENTS_SMALL_POINTS_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_OPPONENTS_SMALL_POINTS_LABEL");
    const TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL = UseLeaguesDictionaryPhrasesSet("TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL");
    const TABLE_SMALL_POINTS_DIFFERENCE_TITLE = UseLeaguesDictionaryPhrasesSet("TABLE_SMALL_POINTS_DIFFERENCE_TITLE");


    return (
        <Col xs={12} className={"league-table-head league-table-row"}>
            <Row className={"league-table-head-content league-table-row-content"}>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-position"}>
                    <span className={"label"}>{TABLE_PLACE_LABEL}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-logo"}></Col>

                <Col xs={8} lg={`auto`} className={"league-table-cell team-name"}>
                    <span>{TABLE_TEAM_TITLE}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-points"}>
                    <span className={"short-label"}>{TABLE_POINTS_SHORT_LABEL}</span>
                    <span className={"label"}>{TABLE_POINTS_LABEL}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-games"}>
                    <span className={"short-label"}>{TABLE_GAMES_SHORT_LABEL}</span>
                    <span className={"label"}>{TABLE_GAMES_TITLE}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-balance"}>
                    <span className={"short-label"}>{TABLE_VICTORIES_SHORT_LABEL} - {TABLE_DEFEATS_SHORT_LABEL}</span>
                    <span className={"label"}>{TABLE_VICTORIES_LABEL} - {TABLE_DEFEATS_LABEL}</span>
                </Col>

                {!amateurLeagueStatus &&
			    <Col xs={`auto`} className={"league-table-cell team-home-games"}>
				    <span className={"short-label"}></span>
                    <span className={"label"}></span>
			    </Col>
                }

                {!amateurLeagueStatus &&
			    <Col xs={`auto`} className={"league-table-cell team-away-games"}>
				    <span className={"short-label"}></span>
                    <span className={"label"}></span>
			    </Col>
                }

                <Col xs={`auto`} className={"league-table-cell team-small-points"}>
                    <span className={"short-label"}>{TABLE_TEAM_SMALL_POINTS_SHORT_LABEL} - {TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL}</span>
                    <span className={"label"}>{TABLE_TEAM_SMALL_POINTS_LABEL} - {TABLE_OPPONENTS_SMALL_POINTS_LABEL}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-small-points-diff"}>
                    <span className={"short-label"}>{TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL}</span>
                    <span className={"label"}>{TABLE_SMALL_POINTS_DIFFERENCE_TITLE}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default LeagueTableHeadComponent;
