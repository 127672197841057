import _ from "lodash";


import TeamTableDataFormatter from "@DataGenerators/Leagues/Tables/TableByLeagueGames/TeamTableData";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";


import LeagueTableTeamsType from "@DataGenerators/Leagues/Tables/_Types/Teams";
import LeagueTableTeamType from "@DataGenerators/Leagues/Tables/_Types/Teams/Team";


export const TableByLeagueGamesGenerator = (
    leagueTeams: LeagueTeamInterface[],
    leagueGames: LeagueGameInterface[]
) : LeagueTableTeamType[] | null => {


    if (!leagueTeams || !leagueGames) return null;

    const Teams: LeagueTableTeamsType = Object.fromEntries(

        leagueTeams.map((Team) => {
           const {
               team_id: teamID,
               team_name: teamName, team_slug: teamSlug,
               team_logo: teamLogo,
           } = Team;
           return [
               teamID,
               {
                   teamName, teamSlug, teamLogo,
                   teamWins: 0, teamLoses: 0, teamPoints: 0,
                   teamSmallPointsIn: 0, teamSmallPointsOut: 0, teamSmallPointsDiff: 0
               }
           ];
        })

    );

    if (leagueGames && leagueGames?.length) {

        for (let Game of leagueGames) {

            const {
                league_game_status: gameStatus,
                league_game_home_team_data: homeTeam, league_game_home_team_points: homeTeamPoints,
                league_game_away_team_data: awayTeam, league_game_away_team_points: awayTeamPoints,
            } = Game;

            if (!["FT", "AET", "FT_WAL"].includes(gameStatus)) continue;

            if (!homeTeam || !awayTeam) continue;

            const {team_id: homeTeamID} = homeTeam;
            const {team_id: awayTeamID} = awayTeam;

            TeamTableDataFormatter(Teams[homeTeamID], homeTeamPoints, awayTeamPoints, gameStatus);
            TeamTableDataFormatter(Teams[awayTeamID], awayTeamPoints, homeTeamPoints, gameStatus);

        }

    }


    const Table = _.orderBy(
        Object.values(Teams),
        ["teamPoints", "teamSmallPointsDiff", "teamName"],
        ["desc", "desc", "asc"]
    );


    return Table;

};


export default TableByLeagueGamesGenerator;
