export const SELECT_ALL_MAN_PL = "Zaznacz wszystkich";
export const SELECT_ALL_MAN_EN = "Select all";
export const UN_SELECT_ALL_MAN_PL = "Odznacz wszystkich";
export const UN_SELECT_ALL_MAN_EN = "Unselect all";

export const I_UNDERSTAND_PHRASE_PL = "Rozumiem"
export const I_UNDERSTAND_PHRASE_EN = "I Understand"

export const LACK_WORD_PL = "Brak";
export const LACK_WORD_EN = "Lack";
export const ADD_WORD_PL = "Dodaj";
export const ADD_WORD_EN = "Add";
export const ABORT_WORD_PL = "Anuluj";
export const ABORT_WORD_EN = "Cancel";

export const DOCUMENT_NAME_TEXT_PL = "Nazwa Dokumentu";
export const DOCUMENT_NAME_TEXT_EN = "Document Name";
export const ADD_NEW_DOCUMENT_TEXT_PL = "Dodaj Dokument";
export const ADD_NEW_DOCUMENT_TEXT_EN = "Add Document";
export const DEALS_AND_DOCUMENTS_TEXT_PL = "Umowy i Dokumenty";
export const DEALS_AND_DOCUMENTS_TEXT_EN = "Deals and Documents";

export const DOWNLOAD_WORD_PL = "pobierz";
export const DOWNLOAD_WORD_EN = "download";

export const NO_FILE_TO_UPLOAD_TEXT_PL = "nie wybrano pliku";
export const NO_FILE_TO_UPLOAD_TEXT_EN = "no file selected";
export const FILE_UPLOAD_SUCCESS_TEXT_PL = "Plik został załadowany";
export const FILE_UPLOAD_SUCCESS_TEXT_EN = "File successfully uploaded";
export const FILE_UPLOAD_ERROR_TEXT_PL = "Plik został załadowany";
export const FILE_UPLOAD_ERROR_TEXT_EN = "File upload error";

export const TIME_WORD_PL = "Czas";
export const TIME_WORD_EN = "Time";

export const START_WORD_PL = "Początek";
export const START_WORD_EN = "Start";
export const FINISH_WORD_PL = "Koniec";
export const FINISH_WORD_EN = "Finish";

export const GAME_WORD_PL = "Mecz";
export const GAME_WORD_EN = "Game";
export const GAME_WORD_GENITIVE_PL = "Meczu";
export const GAME_WORD_GENITIVE_EN = "Game";
export const GAME_START_PHRASE_PL = "Początek Meczu";
export const GAME_START_PHRASE_EN = "Game Start";
export const GAME_FINISH_PHRASE_PL = "Koniec Meczu";
export const GAME_FINISH_PHRASE_EN = "Game Finish";
