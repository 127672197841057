import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import ROUTER from "@Router";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";


const TEXTS: any = {
    PL: {team_points_text: "Pkt"},
    EN: {team_points_text: "Pts"},
};


import LeagueTableTeamType from "@DataGenerators/Leagues/Tables/_Types/Teams/Team";


export type LeaguesTableTeamComponentProps = {
    teamData: LeagueTableTeamType;
    teamPosition: number;
};


export const LeaguesTableTeamComponent: React.FC<LeaguesTableTeamComponentProps> = ({
    teamData = null,
    teamPosition = 1
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {team_points_text: TEAM_POINTS_TEXT} = TEXTS[activeLanguage];


    const TEAM_PAGE_URL = ROUTER[activeLanguage].GAMES.TEAMS.TEAM.URL;


    if (!teamData) return null;


    const {teamSlug, teamLogo, teamName, teamWins, teamLoses, teamPoints} = teamData;

    return (
        <Row className={"tables-table-team"}>

            <Col xs={"auto"} lg={1} className={"table-team-position"}>
                <span>{teamPosition}</span>
            </Col>

            <Col xs={1} lg={2} className={"table-team-logo"}>
                {!!teamLogo &&
                <NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`}>
                    <img src={teamLogo} alt={teamName}/>
                </NavLink>
                }
            </Col>

            <Col xs={6} lg={5} className={"table-team-name"}>
                <NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`}>
                    <span>{teamName}</span>
                </NavLink>
            </Col>

            <Col xs={"auto"} lg={1} className={"table-team-games"}>
                <span>{teamWins + teamLoses}</span>
            </Col>

            <Col xs={"auto"} lg={1} className={"table-team-results"}>
                <span>{teamWins} - {teamLoses}</span>
            </Col>

            <Col xs={2} className={"table-team-points"}>
                <span>{teamPoints} {TEAM_POINTS_TEXT}</span>
            </Col>

        </Row>
    );

};


export default LeaguesTableTeamComponent;
