import React, { useEffect, useState } from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import { MainContentLoaderComponent } from "@LoadersElements";


import MessageComponent from "@MessagesElements/Content";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import TextPageComponent from "@Views/Elements/TextPage";


import "./index.scss";


import { SystemPageInterface } from "@Interfaces/Api";


export interface RegulationsPageComponentProps {}


export const RegulationsPageComponent: React.FC<RegulationsPageComponentProps> = () => {


    const [RegulationsPageContent, setRegulationsPageContent] = useState<SystemPageInterface | null>(null);
    const [RegulationsPageContentError, setRegulationsPageContentError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL + "/content/pages/get-page-by-type/?page_type=regulations-page";

        const getRegulationsPageContent = async () => {

            try {

                const response = await REST_API_REQUEST(API_URL);

                if (response.ok) {

                    const responseContent = await response.json();
                    setRegulationsPageContent(responseContent);

                } else {

                    if (response.status === 404) {
                        setRegulationsPageContentError({ errorCode: 500, errorMessage: "Brak treści regulaminu" });
                    } else {
                        setRegulationsPageContentError({ errorCode: 500, errorMessage: "" });
                    }

                }

            } catch (e) {
                setRegulationsPageContentError({ errorCode: 500, errorMessage: "" });
            }

        }

        getRegulationsPageContent();

    }, []);


    useEffect(() => {
        return () => {
            setRegulationsPageContent(null);
            setRegulationsPageContentError(null);
        };
    }, []);


    if (RegulationsPageContentError) return <MessageComponent messageText={RegulationsPageContentError.errorMessage} />;


    if (RegulationsPageContent === null) return <MainContentLoaderComponent />;


    const {
        content_page_title: regulationsPageTitle,
        content_page_main_text: regulationsPageContent
    } = RegulationsPageContent;


    return (
        <TextPageComponent
            pageTitle={regulationsPageTitle}
            pageContent={regulationsPageContent}
        />
    )

};


export default RegulationsPageComponent;
