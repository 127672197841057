import React, { useEffect, useState } from "react";

import { UseErrorMessage } from "@Hooks/Texts";

import { UseLeaguesList } from "@Services/Utilities/ApiHooks";


import { Container, Row } from "react-bootstrap";


import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";
import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import HomePageLeaguesTablesComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables";
import HomePageLeaguesStatisticsComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Statistics";
import HomePageLeaguesPlayerOfTheRoundComponent from "@Components/Views/Pages/Common/HomePage/Leagues/BestPlayer";


import { LeagueInterface } from "@Interfaces/Api";


import "./index.scss";


export const HomePageLeaguesComponent = () => {


    const DEFAULT_DATA_REQUEST_ERROR = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");


    const [LeaguesList, setLeaguesList] = useState<LeagueInterface[] | null>(null);
    const [LeaguesListError, setLeaguesListError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {
        /** Get Leagues in active Season */
        UseLeaguesList(
            setLeaguesList, setLeaguesListError
        );
    }, []);


    const [selectedLeagueID, setSelectedLeagueID] = useState<number | null>(null);
    const [selectedLeague, setSelectedLeague] = useState<LeagueInterface | null>(null);
    const [selectedSeasonID, setSelectedSeasonID] = useState<number | null>(null);


    useEffect(() => {
        return () => {
            setLeaguesList(null);
            setLeaguesListError(null);
            setSelectedLeagueID(null);
            setSelectedSeasonID(null);
            setSelectedLeague(null);
        };
    }, []);


    if (!!LeaguesListError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR} />;
    }


    if (!LeaguesList) {
        return null;
    }


    return (
        <Container fluid={true} className={`home-page-leagues-component`}>
            <Container className={"home-page-leagues-container"}>
                <Row className={"home-page-leagues-content"}>

                    <HomePageLeaguesTablesComponent
                        leaguesList={LeaguesList}
                        selectedSeasonID={selectedSeasonID}
                        selectedSeasonIDHandler={setSelectedSeasonID}
                        selectedLeagueID={selectedLeagueID}
                        selectedLeagueIDHandler={setSelectedLeagueID}
                        selectedLeague={selectedLeague}
                        selectedLeagueHandler={setSelectedLeague}
                    />

                    <HomePageLeaguesStatisticsComponent
                        leaguesList={LeaguesList}
                        selectedLeagueID={selectedLeagueID}
                        selectedLeagueIDHandler={setSelectedLeagueID}
                    />

                    {/*<HomePageLeaguesPlayerOfTheRoundComponent*/}
                    {/*    selectedLeagueID={selectedLeagueID}*/}
                    {/*/>*/}

                </Row>
            </Container>
        </Container>
    );

};


export default HomePageLeaguesComponent;
