import React, {useState, useEffect} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";

import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import SystemErrorMessages from "@GlobalData/Messages/System/Errors";

import HttpResponseErrorMessages from "@GlobalData/Messages/HTTP/Response/Errors";


import MessageComponent from "@Components/Elements/Messages/Content";


import LeaguesTableTeamComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Table/Team";


import TableByLeagueGamesGenerator from "@DataGenerators/Leagues/Tables/TableByLeagueGames";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";
import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";



const TEXTS: any = {
    PL: {
        no_teams_text: "Brak drużyn w lidze",
        no_games_text: "Brak rozegranych meczów",
        full_table_label_text: "Pełna Tabela"
    },
    EN: {
        no_teams_text: "No teams in league",
        no_games_text: "No played games",
        full_table_label_text: "Full Table"
    },
};


export type LeaguesTablesTableComponentProps = {
    selectedLeagueID: number | null;
    selectedSeasonID: number | null;
    selectedLeague: LeagueInterface | null;
};


export const LeaguesTablesTableComponent: React.FC<LeaguesTablesTableComponentProps> = ({
    selectedLeagueID = null, selectedSeasonID, selectedLeague
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {no_games_text: NO_GAMES_TEXT, full_table_label_text: FULL_TABLE_LABEL_TEXT} = TEXTS[activeLanguage];


    const LEAGUE_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUE.MAIN_URL;
    const LEAGUE_GAMES_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.GAMES.GAMES.MAIN_URL;


    const [leagueTeams, setLeagueTeams] = useState<LeagueTeamInterface[] | null>(null);
    const [leagueTeamsError, setLeagueTeamsError] = useState<ErrorMessageInterface | null>(null);

    const [leagueGames, setLeagueGames] = useState<LeagueGameInterface[] | null>(null);
    const [leagueGamesError, setLeagueGamesError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!LEAGUE_GAMES_API_URL || !selectedLeagueID) {
            setLeagueTeams([]);
            setLeagueGames([]);
            return;
        }

        setLeagueTeams(null);
        setLeagueGames(null);

        const getLeagueTeamsAndRounds = async () => {

            try {

                const response = await REST_API_REQUEST(`${LEAGUE_API_URL}/${selectedLeagueID}/league-teams/`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setLeagueTeams(responseContent);
                } else {
                    setLeagueTeamsError({errorCode: response.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueTeamsError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

            try {

                const response = await REST_API_REQUEST(`${LEAGUE_GAMES_API_URL}/?league_game_league=${selectedLeagueID}&league_game_round__league_round_type=1&all_results=true`);

                if (response.ok) {
                    const responseContent = await response.json();
                    setLeagueGames(responseContent);
                } else {
                    setLeagueGamesError({errorCode: response.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueGamesError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

        };

        getLeagueTeamsAndRounds();

    }, [selectedLeagueID]);


    useEffect(() => {
        return () => {
            setLeagueTeams(null);
            setLeagueTeamsError(null);
            setLeagueGames(null);
            setLeagueGamesError(null);
        };
    }, []);


    if (!!leagueTeamsError) return <MessageComponent xs={9} lg={9} messageText={leagueTeamsError?.errorMessage} messageType={"error"} cssClasses={"align-content-top"} />;
    if (!!leagueGamesError) return <MessageComponent xs={9} lg={9} messageText={leagueGamesError?.errorMessage} messageType={"error"} cssClasses={"align-content-top"} />;


    if (!leagueGames || !leagueTeams) return <SingleContentLoaderComponent xs={9} lg={9} cssClasses={"no-margin-top"} />;


    if (!leagueTeams?.length) return <MessageComponent xs={9} lg={9} messageText={NO_GAMES_TEXT} cssClasses={"align-content-top"} />;
    if (!leagueGames?.length) return <MessageComponent xs={9} lg={9} messageText={NO_GAMES_TEXT} cssClasses={"align-content-top"} />;


    if (!selectedLeague) return null;


    const {league_groups: selectedLeagueGroups} = selectedLeague;


    let LeagueTableComponent: React.ReactNode = null;


    if (selectedLeagueGroups && selectedLeagueGroups.length > 1) {

        const GroupsComponent = [];

        for (let Group of selectedLeagueGroups) {

            const {league_group_name: groupName, league_group_teams_ids: groupTeamsIDs} = Group;

            if (!groupTeamsIDs) continue;

            const groupTeams = leagueTeams.filter((Team) => {
                const {team_id: teamID} = Team;
                return groupTeamsIDs.includes(teamID);
            });

            const groupTable = TableByLeagueGamesGenerator(groupTeams, leagueGames);

            if (!groupTable) continue;

            GroupsComponent.push(
                <Col xs={12} className={"leagues-tables-group"}>
                    <Row className={"leagues-tables-group-header"}>
                        <Col xs={12} className={"group-header"}>
                            <span>{groupName}</span>
                        </Col>
                    </Row>
                    {groupTable.map((team, i) =>
                    <LeaguesTableTeamComponent key={i} teamData={team} teamPosition={i + 1} />
                    )}
                </Col>
            )

        }

        LeagueTableComponent = GroupsComponent;

    } else {
        const LeagueTable = TableByLeagueGamesGenerator(leagueTeams, leagueGames);
        if (LeagueTable) {
            LeagueTableComponent = LeagueTable.map((team, i) =>
                <LeaguesTableTeamComponent key={i} teamData={team} teamPosition={i + 1} />
            );
        }
    }


    if (!LeagueTableComponent) return null;


    return (
        <Col xs={9} lg={9} className={"leagues-tables-table"}>

            {LeagueTableComponent}

            <Row className={"leagues-tables-full-table-link"}>
                <Col xs={12} className={"leagues-tables-full-table leagues-tables-full-table-component"}>
                    <NavLink
                        to={{
                            pathname: `/tabele`,
                            state: {
                                selectedSeason: selectedSeasonID,
                                selectedLeague: selectedLeagueID
                            }
                        }}
                        title={``}
                    >
                        <span>{FULL_TABLE_LABEL_TEXT}</span>
                    </NavLink>
                </Col>
            </Row>

        </Col>
    );

};


export default LeaguesTablesTableComponent;
