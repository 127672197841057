import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";

import Dictionary from "@GlobalData/Localization/Dictionary";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import NumberFormat from "react-number-format";


import NO_AVATAR_IMAGE from "@Images/no-avatar.jpg";


import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


export interface PlayersTop5PlayerComponentProps {
    player: PlayerLeagueStatisticsInterface;
    position: number;
    selectedStatisticsMainType: "MEDIUM" | "TOTAL";
}


export const PlayersTop5PlayerComponent:React.FC<PlayersTop5PlayerComponentProps> = ({
    player, position, selectedStatisticsMainType
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        link_go_to_player_page_text: LINK_GO_TO_PLAYER_PAGE_TEXT
    } = Dictionary[activeLanguage];


    if (!player) return null;


    const {
        player_data: {
            player_slug: playerSlug,
            player_first_name: playerFirstName, player_last_name: playerLastName,
            player_profile_image: playerAvatar
        }, player_score: playerScore
    } = player;


    return (
        <Col xs={12} className={"team-players-top5-player"}>
            <Row className={"team-players-top5-player-content"}>

                <Col xs={1} className={"player-position"}>
                    <span>{position}</span>
                </Col>

                <Col xs={2} className={"player-avatar"}>
                    <NavLink to={`/zawodnicy/${playerSlug}`} title={LINK_GO_TO_PLAYER_PAGE_TEXT}>
                        {!!playerAvatar && <img src={playerAvatar} alt={``} />}
                    </NavLink>
                </Col>

                <Col xs={7} lg={6} className={"player-name"}>
                    <NavLink to={`/zawodnicy/${playerSlug}`} title={LINK_GO_TO_PLAYER_PAGE_TEXT}>
                        <span>{playerFirstName} {playerLastName}</span>
                    </NavLink>
                </Col>

                <Col xs={2} lg={3} className={"player-score"}>
                    <NumberFormat value={playerScore} decimalScale={selectedStatisticsMainType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                </Col>

            </Row>
        </Col>
    );


};


export default PlayersTop5PlayerComponent;
