import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import Dictionary from "@GlobalData/Localization/Dictionary";


import REST_API_REQUEST from "@Services/HTTP/Requests/Request";
import REST_API_ENDPOINTS_URLS from "@ApiData";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";

import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";
import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import NumberFormat from "react-number-format";


import "./index.scss";

import dateFormat from "dateformat";


export interface TeamStatisticsComponentProps {
    teamID: number;
}


interface TeamStatisticsInterface {
    events: {AST?: number; TEAM_REB?: number, PLAYER_D_REB?: number, PLAYER_O_REB?: number};
    last_game: {game_id: number, opponent: LeagueTeamInterface; result: {team_points: number; opponent_points: number;}};
    next_game: {game_id: number, game_date: string | Date | null, game_arena: {
            league_arena_name: string,
            league_arena_village: string,
            league_arena_address: string,
        } | null, opponent: LeagueTeamInterface};
    points: {IN: number; OUT: number};
    results: {W: number; L: number;};
}


export const TeamStatisticsComponent: React.FC<TeamStatisticsComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        team_data_team_points_label_text: TEAM_DATA_TEAM_POINTS_LABEL_TEXT,
        team_data_opponents_points_label_text: TEAM_DATA_OPPONENTS_POINTS_LABEL_TEXT,
        team_data_team_assists_label_text: TEAM_DATA_TEAM_ASSISTS_LABEL_TEXT,
        team_data_team_rebounds_label_text: TEAM_DATA_TEAM_REBOUNDS_LABEL_TEXT,
        team_data_team_balance_label_text: TEAM_DATA_TEAM_BALANCE_LABEL_TEXT,
        team_data_team_last_game_label_text: TEAM_DATA_TEAM_LAST_GAME_LABEL_TEXT,
        team_data_team_next_game_label_text: TEAM_DATA_TEAM_NEXT_GAME_LABEL_TEXT
    } = Dictionary[activeLanguage];


    const API_TEAM_STATISTICS_URL = REST_API_ENDPOINTS_URLS.GAMES.TEAMS.TEAM.MAIN_URL;


    const [teamStatistics, setTeamStatistics] = useState<TeamStatisticsInterface | null>(null);
    const [teamStatisticsError, setTeamStatisticsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!teamID || !API_TEAM_STATISTICS_URL) return;

        const getTeamStatistics = async () => {
            try {
                const response = await REST_API_REQUEST(
                    `${API_TEAM_STATISTICS_URL}/${teamID}/team-information/`
                );
                if (response.ok) {
                    const responseContent = await response.json();
                    setTeamStatistics(responseContent);
                } else {
                    setTeamStatisticsError({errorCode: response.status, errorMessage: ""});
                }
            } catch (e) {
                setTeamStatisticsError({errorCode: 501, errorMessage: ""});
            }
        };
        getTeamStatistics();
    }, [teamID, API_TEAM_STATISTICS_URL]);

    useEffect(() => {
        return () => {
            setTeamStatistics(null);
            setTeamStatisticsError(null);
        };
    }, []);


    if (!teamID || !API_TEAM_STATISTICS_URL) return null;


    if (!!teamStatisticsError) return <ErrorMessageComponent messageText={teamStatisticsError.errorMessage} />;


    if (!teamStatistics) return <SingleContentLoaderComponent loaderColor={"#17366E"} />;


    const {
        points: {IN: teamPoints, OUT: teamOpponentsPoints},
        events: teamEvents,
        results: {W: teamVictories, L: teamDefeats},
        next_game: teamNextGame,
        last_game: teamLastGame
    } = teamStatistics;

    let teamAssists: number | null = null;
    let teamRebounds: number | null = null;

    if (teamEvents) {
        const { AST, PLAYER_D_REB, PLAYER_O_REB } = teamEvents

        if (AST) {
            teamAssists = AST;
        }

        teamRebounds = 0;

        if (PLAYER_D_REB) {
            teamRebounds += PLAYER_D_REB;
        }
        if (PLAYER_O_REB) {
            teamRebounds += PLAYER_O_REB;
        }

    }

    const teamGamesNumber = teamVictories + teamDefeats;

    let teamNextGameID: number | null = null;
    let teamNextOpponentName: string | null = null;
    let teamNextGameDate: string | Date | null = null;
    let teamNextGameArena: string | null = null;

    let teamLastGameID: number | null = null;
    let teamLastGamePoints: number | null = null;
    let teamLastGameOpponentPoints: number | null = null;
    let teamLastOpponentName: string | null = null;


    if (!!teamNextGame) {
        const {
            game_id: gameID,
            game_date: gameDate,
            game_arena: gameArena,
            opponent: {team_name: teamName},

        } = teamNextGame;
        teamNextGameID = gameID;
        teamNextOpponentName = teamName;
        if (gameDate) teamNextGameDate = dateFormat(gameDate, "dd-mm-yyyy HH:MM");
        if (gameArena) {
            const { league_arena_name: arenaName, league_arena_village: arenaVillage } = gameArena;
            teamNextGameArena = `${arenaName}, ${arenaVillage}`;
        }
    }

    if (!!teamLastGame) {
        const {
            game_id: gameID,
            opponent: {team_name: teamName},
            result: {team_points: teamPoints, opponent_points: opponentPoints}
        } = teamLastGame;
        teamLastGameID = gameID;
        teamLastOpponentName = teamName;
        teamLastGamePoints = teamPoints;
        teamLastGameOpponentPoints = opponentPoints;
    }


    return (
        <ContainerSectionComponent classes={"team-page__team-statistics"}>

            <Col xs={10} lg={"auto"} className={"information-label"}>
                <span className={"mob"}>{`Średnio`}</span>
                <span className={"des"}>{`Śr.`}</span>
            </Col>

            <Col xs={3} lg={"auto"} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_POINTS_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                        {teamPoints ?
                        <NumberFormat value={teamPoints / teamGamesNumber} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        :
                        <span>-</span>
                        }
                    </Col>
                </Row>
            </Col>

            <Col xs={3} lg={"auto"} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_OPPONENTS_POINTS_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                        {teamOpponentsPoints ?
                        <NumberFormat className={"info-value"} value={teamOpponentsPoints / teamGamesNumber} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        :
                        <span>-</span>
                        }
                    </Col>
                </Row>
            </Col>

            <Col xs={2} lg={"auto"} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_REBOUNDS_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                        {teamRebounds ?
                        <NumberFormat className={"info-value"} value={teamRebounds / teamGamesNumber} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        :
                        <span>-</span>
                        }
                    </Col>
                </Row>
            </Col>

            <Col xs={2} lg={"auto"} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_ASSISTS_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                        {teamAssists ?
                        <NumberFormat className={"info-value"} value={teamAssists / teamGamesNumber} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        :
                        <span>-</span>
                        }
                    </Col>
                </Row>
            </Col>

            <Col xs={2} lg={"auto"} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_BALANCE_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                        <span className={"info-value"}>{teamVictories} - {teamDefeats}</span>
                    </Col>
                </Row>
            </Col>

            <Col xs={6} lg={2} className={"team-information"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_LAST_GAME_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                    {!!teamLastGame ?
                        <NavLink to={`/mecze/${teamLastGameID}`}title={``}>
                            <span>{teamLastOpponentName}</span>
                            <span>{teamLastGamePoints} : {teamLastGameOpponentPoints}</span>
                        </NavLink>
                        :
                        <span>-</span>
                    }
                    </Col>
                </Row>
            </Col>

            <Col xs={6} lg={2} className={"team-information team-next-game"}>
                <Row className={"team-information-content"}>
                    <Col xs={12} className={"info-label"}>{TEAM_DATA_TEAM_NEXT_GAME_LABEL_TEXT}</Col>
                    <Col xs={12} className={"info-value"}>
                    {!!teamNextGame ?
                        <NavLink to={`/mecze/${teamNextGameID}`} title={``}>
                            <span>{teamNextOpponentName}</span>
                            {teamNextGameDate && <span className={"game-date"}>{teamNextGameDate}</span>}
                            {teamNextGameArena && <span className={"game-arena"}>{teamNextGameArena}</span>}
                        </NavLink>
                        :
                        <span>-</span>
                    }
                    </Col>
                </Row>
            </Col>

        </ContainerSectionComponent>
    );

};


export default TeamStatisticsComponent;
