import React from "react";


import {
    UseLeaguesDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Container, Row } from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import ResultsPageLeaguesSwitcherComponent from "@Components/Views/Pages/Games/Results/Switcher/Leagues";
import ResultsPageRoundsSwitcherComponent from "@Components/Views/Pages/Games/Results/Switcher/Rounds";


import {
    LeagueInterface, LeagueRoundInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ResultsPageDataSwitcherComponentProps {
    selectedLeagueID: number;
    selectedLeagueHandler(leagueID: number): void;
    selectedLeagueRoundID: number;
    selectedLeagueRoundIDHandler(leagueRoundID: number): void;
    LeaguesList: LeagueInterface[];
    SelectedLeagueRoundsList: LeagueRoundInterface[];
}


export const ResultsPageDataSwitcherComponent: React.FC<ResultsPageDataSwitcherComponentProps> = ({
    selectedLeagueID, selectedLeagueHandler,
    selectedLeagueRoundID, selectedLeagueRoundIDHandler,
    LeaguesList, SelectedLeagueRoundsList
}) : JSX.Element | null => {


    const NO_LEAGUES_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_LEAGUES_MESSAGE_TEXT");


    if (LeaguesList?.length === 0) {
        return <MessageComponent messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"margin-top-15"} />;
    }


    return (
        <Container fluid={true} className={"page-component-switcher-block results-page-switcher-component leagues-schedules__leagues-and-rounds-switcher"}>
            <Container className={"page-component-switcher-container leagues-schedules__leagues-and-rounds-switcher__container"}>
                <Row className={"page-component-switcher-content leagues-schedules__leagues-and-rounds-switcher__content"}>

                    <ResultsPageLeaguesSwitcherComponent
                        selectedLeagueID={selectedLeagueID}
                        selectedLeagueIDHandler={selectedLeagueHandler}
                        Leagues={LeaguesList}
                    />

                    <ResultsPageRoundsSwitcherComponent
                        selectedLeagueID={selectedLeagueID}
                        selectedLeagueRoundID={selectedLeagueRoundID}
                        selectedLeagueRoundHandler={selectedLeagueRoundIDHandler}
                        LeagueRounds={SelectedLeagueRoundsList}
                    />

                </Row>
            </Container>
        </Container>
    );

};


export default ResultsPageDataSwitcherComponent;
