import React, { useEffect, useState } from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import { MainContentLoaderComponent } from "@LoadersElements";



import MessageComponent from "@MessagesElements/Content";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import TextPageComponent from "@Views/Elements/TextPage";


import { SystemPageInterface } from "@Interfaces/Api";


export interface PrivacyPolicyPageComponentProps {}


export const PrivacyPolicyPageComponent: React.FC<PrivacyPolicyPageComponentProps> = () => {


    const [PrivacyPolicyPageContent, setPrivacyPolicyPageContent] = useState<SystemPageInterface | null>(null);
    const [PrivacyPolicyPageContentError, setPrivacyPolicyPageContentError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL + "/content/pages/get-page-by-type/?page_type=privacy-policy-page";

        const getPrivacyPolicyPageContent = async () => {

            try {

                const response = await REST_API_REQUEST(API_URL);

                if (response.ok) {

                    const responseContent = await response.json();
                    setPrivacyPolicyPageContent(responseContent);

                } else {

                    if (response.status === 404) {
                        setPrivacyPolicyPageContentError({ errorCode: 500, errorMessage: "Brak treści regulaminu" });
                    } else {
                        setPrivacyPolicyPageContentError({ errorCode: 500, errorMessage: "" });
                    }

                }

            } catch (e) {
                setPrivacyPolicyPageContentError({ errorCode: 500, errorMessage: "" });
            }

        }

        getPrivacyPolicyPageContent();

    }, []);


    useEffect(() => {
        return () => {
            setPrivacyPolicyPageContent(null);
            setPrivacyPolicyPageContentError(null);
        };
    }, []);


    if (PrivacyPolicyPageContentError) return <MessageComponent messageText={PrivacyPolicyPageContentError.errorMessage} />;


    if (PrivacyPolicyPageContent === null) return <MainContentLoaderComponent />;


    const {
        content_page_title: privacyPolicyPageTitle,
        content_page_main_text: privacyPolicyPageContent
    } = PrivacyPolicyPageContent;


    return (
        <TextPageComponent
            pageTitle={privacyPolicyPageTitle}
            pageContent={privacyPolicyPageContent}
        />
    )

};


export default PrivacyPolicyPageComponent;
