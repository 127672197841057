import React, {useEffect, useState} from "react";
import {LeagueGameInterface, LeagueTeamInterface} from "@Interfaces/Api";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import {SingleContentLoaderComponent} from "@LoadersElements";
import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";
import {UseRoute} from "@Hooks/Routes";
import dateFormat from "dateformat";
import {Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


export interface TeamNextGamesListComponentProps {
    teamID: number;
}


export const TeamNextGamesListComponent: React.FC<TeamNextGamesListComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    const LeagueGameRoute = UseRoute("GAMES");
    const TeamRoute = UseRoute("TEAMS");


    const [TeamNextGames, setTeamNextGames] = useState<LeagueGameInterface[] | null>(null);
    const [TeamNextGamesError, setTeamNextGamesError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!teamID) return;

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const getTeamNextGames = async () => {

            try {

                const response = await REST_API_REQUEST(
                    `${API_URL}/teams/teams/${teamID}/team-next-games/?results_limit=5`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setTeamNextGames(responseContent);
                } else {
                    setTeamNextGamesError({ errorMessage: "", errorCode: response.status });
                }

            } catch (e) {
                setTeamNextGamesError({ errorMessage: "", errorCode: 500 });
            }

        }

        getTeamNextGames();

    }, [teamID]);

    useEffect(() => {
        return () => {
            setTeamNextGames(null);
            setTeamNextGamesError(null);
        };
    }, []);


    if (TeamNextGamesError) {
        return <ErrorMessageComponent messageText={`Nie udało się pobrać meczów`} />;
    }

    if (!TeamNextGames) {
        return <SingleContentLoaderComponent />;
    }

    if (!teamID) {
        return null;
    }

    const TeamNextGamesList = TeamNextGames.map((Game, i) => {

        const {
            league_game_id: gameID, league_game_date: gameDate, league_game_arena_data: gameArena,
            league_game_home_team_data: gameHomeTeam, league_game_away_team_data: gameAwayTeam,
        } = Game;

        let gameDay = "-";
        let gameHour = "-";

        if (gameDate) {
            gameDay = dateFormat(gameDate, "dd.mm");
            gameHour = dateFormat(gameDate, "HH:MM");
        }

        let gameArenaName = "";

        if (gameArena) {
            const { league_arena_name: arenaName, league_arena_village: arenaVillage } = gameArena;
            gameArenaName = arenaName;
            if (arenaVillage) {
                gameArenaName += `, ${arenaVillage}`
            }
        }

        let opponentTeamID = 0;
        let opponentTeamName = "-";

        if (gameHomeTeam) {
            const { team_id: hTeamID, team_name: teamName } = gameHomeTeam;
            if (teamID !== hTeamID) {
                opponentTeamID = hTeamID;
                opponentTeamName = teamName;
            }
        }

        if (gameAwayTeam) {
            const { team_id: aTeamID, team_name: teamName } = gameAwayTeam;
            if (teamID !== aTeamID) {
                opponentTeamID = aTeamID;
                opponentTeamName = teamName;
            }
        }

        return (
            <Col key={i} xs={12} className={"team-page__team-players__team-games__list__game"}>
                <Row className={"team-page__team-players__team-games__list__game__content"}>

                    <Col xs={1} className={"game-order-nr"}>
                        <NavLink to={`/${LeagueGameRoute.routeURL}/${gameID}`}>
                            <span>{i + 1}</span>
                        </NavLink>
                    </Col>

                    <Col xs={1} className={"game-day"}>
                        <NavLink to={`/${LeagueGameRoute.routeURL}/${gameID}`}>
                            <span>{gameDay}</span>
                        </NavLink>
                    </Col>

                    <Col xs={1} className={"game-hour"}>
                        <NavLink to={`/${LeagueGameRoute.routeURL}/${gameID}`}>
                            <span>{gameHour}</span>
                        </NavLink>
                    </Col>

                    <Col xs={3} className={"game-arena"}>
                        <span>{gameArenaName}</span>
                    </Col>

                    <Col xs={6} className={"game-opponent-team"}>
                        <NavLink to={`/${TeamRoute.routeURL}/${opponentTeamID}`}>
                            <span>{opponentTeamName}</span>
                        </NavLink>
                    </Col>

                </Row>
            </Col>
        )

    });

    return (
        <Col xs={12} className={"team-page__team-players__team-games__list"}>
            <Row className={"team-page__team-players__team-games__list__content"}>

                <Col xs={12} className={"team-page__team-players__team-games__list__header"}>
                    <Row className={"team-page__team-players__team-games__list__header__content"}>
                        <Col xs={1} className={"game-order-nr"}>
                            <span></span>
                        </Col>
                        <Col xs={1} className={"game-day"}>
                            <span>Data</span>
                        </Col>
                        <Col xs={1} className={"game-hour"}>
                            <span>Godz.</span>
                        </Col>
                        <Col xs={3} className={"game-arena"}>
                            <span>Miejsce</span>
                        </Col>
                        <Col xs={6} className={"game-opponent-team"}>
                            <span>Przeciwko</span>
                        </Col>
                    </Row>
                </Col>

                {TeamNextGamesList}

            </Row>
        </Col>
    );

};
