import React, {useEffect, useState} from "react";


import { UseSetPopupMessageAction } from "@StatesActions";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import { Col, Row } from "react-bootstrap";

import { AiFillDelete, AiOutlineMail } from "react-icons/all";


import {
    FormTextFieldComponent
} from "@FormsElements";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";

import { UseFormFieldErrorMessage } from "@Hooks/Texts/Sets/Forms/Fields/Field";


import { isEmail, isFirstName, isLastName, isSocialID } from "@Services/Utilities/Tools/Validators";


import { UseErrorMessage, UseInformationMessage, UseSuccessMessage } from "@Hooks/Texts";

import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import "./index.scss";


import { LeaguePlayerInterface } from "@Interfaces/Api";


export interface TeamCaptainTeamPlayerComponentProps {
    teamID: number;
    playerID: number;
    player: LeaguePlayerInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainTeamPlayerComponent: React.FC<TeamCaptainTeamPlayerComponentProps> = ({
    teamID,
    playerID, player,
    updatePlayerTrigger
}) : JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const DEFAULT_ERROR_MESSAGE = UseErrorMessage("DEFAULT");
    const DEFAULT_UPDATE_SUCCESS_MESSAGE = UseSuccessMessage("DEFAULT_UPDATE_DATA_RESPONSE");
    const DEFAULT_DELETE_SUCCESS_MESSAGE = UseSuccessMessage("DEFAULT_DELETED_STATUS");
    const DEFAULT_MESSAGE_SENT_MESSAGE = UseSuccessMessage("DEFAULT_MESSAGE_SENT_STATUS");
    const DEFAULT_SEND_MESSAGE_CONFIRMATION_MESSAGE = UseInformationMessage("DEFAULT_SEND_MESSAGE_CONFIRMATION");
    const DEFAULT_DELETE_CONFIRMATION_MESSAGE = UseInformationMessage("DEFAULT_DELETE_CONFIRMATION");


    const TEAM_PLAYER_FIRST_NAME_FIELD_ERROR = UseFormFieldErrorMessage("FIRST_NAME");
    const TEAM_PLAYER_LAST_NAME_FIELD_ERROR = UseFormFieldErrorMessage("LAST_NAME");
    const TEAM_PLAYER_SOCIAL_ID_FIELD_ERROR = UseFormFieldErrorMessage("SOCIAL_ID");
    const TEAM_PLAYER_EMAIL_ADDRESS_FIELD_ERROR = UseFormFieldErrorMessage("EMAIL_ADDRESS");
    const TEAM_PLAYER_NUMBER_ADDRESS_FIELD_ERROR = UseFormFieldErrorMessage("NUMBER");

    const PLAYER_NOT_ACTIVATED_STATUS = UsePlayersDictionaryPhrasesSet("NOT_ACTIVATED_STATUS");
    const PLAYER_REGULATIONS_NOT_ACCEPTED_STATUS = UsePlayersDictionaryPhrasesSet("REGULATIONS_NOT_ACCEPTED_STATUS");

    const SEND_ACTIVATION_EMAIL_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL");
    const SEND_REGULATIONS_EMAIL_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL");
    const REMOVE_PLAYER_FROM_TEAM_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL");


    interface FormErrorsInterface {
        playerFirstName: FormFieldValueErrorInterface | null;
        playerLastName: FormFieldValueErrorInterface | null;
        playerEmailAddress: FormFieldValueErrorInterface | null;
        playerSocialID: FormFieldValueErrorInterface | null;
        playerNumber: FormFieldValueErrorInterface | null;
    }

    const FormErrorsDefaults: FormErrorsInterface = {
        playerFirstName: null, playerLastName: null,
        playerEmailAddress: null, playerSocialID: null,
        playerNumber: null
    };

    const [playerFormErrors, setFormErrors] = useState<FormErrorsInterface>(FormErrorsDefaults);

    const [teamPlayerFirstName, setTeamPlayerFirstName] = useState<string>("");
    const [teamPlayerLastName, setTeamPlayerLastName] = useState<string>("");
    const [teamPlayerEmailAddress, setTeamPlayerEmailAddress] = useState<string>("");
    const [teamPlayerSocialID, setTeamPlayerSocialID] = useState<string>("");
    const [teamPlayerNumber, setTeamPlayerNumber] = useState<string | number>("");

    const changeTeamPlayerFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isFirstName(value) && playerFormErrors.playerFirstName) {
            setFormErrors({...playerFormErrors, playerFirstName: null});
        }
        setTeamPlayerFirstName(value);
    };

    const changeTeamPlayerLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isLastName(value) && playerFormErrors.playerLastName) {
            setFormErrors({...playerFormErrors, playerLastName: null});
        }
        setTeamPlayerLastName(value);
    };

    const changeTeamPlayerEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isEmail(value) && playerFormErrors.playerEmailAddress) {
            setFormErrors({...playerFormErrors, playerEmailAddress: null});
        }
        setTeamPlayerEmailAddress(value);
    };

    const changeTeamPlayerSocialID = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isSocialID(value) && playerFormErrors.playerSocialID) {
            setFormErrors({...playerFormErrors, playerSocialID: null});
        }
        setTeamPlayerSocialID(value);
    };

    const changeTeamPlayerNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value && +value) {
            setFormErrors({...playerFormErrors, playerNumber: null});
        }
        setTeamPlayerNumber(value);
    };

    useEffect(() => {

        if (!player) return;

        const {
            player_social_id: playerSocialID, player_email_address: playerEmailAddress,
            player_first_name: playerFirstName, player_last_name: playerLastName,
            player_number: playerNumber
        } = player;

        setTeamPlayerFirstName(playerFirstName);
        setTeamPlayerLastName(playerLastName);
        setTeamPlayerEmailAddress(playerEmailAddress);
        setTeamPlayerSocialID(playerSocialID?.toString() || "");
        setTeamPlayerNumber(playerNumber || "");

    }, [player]);


    const [sendEmailMessageConfirmStatus, setSendEmailMessageConfirmStatus] = useState(false);


    const [removePlayerFromTeamConfirmStatus, setRemovePlayerFromTeamConfirmStatus] = useState(false);

    useEffect(() => {
        return () => {
            setRemovePlayerFromTeamConfirmStatus(false);
        };
    }, []);


    if (!player || !playerID) return null;


    const {
        player_id: playerId, player_slug: playerSlug,
        player_activated_status: playerActivatedStatus,
        player_regulations_acceptation_date: playerRegulationsAcceptationDate,
        player_privacy_acceptation_date: playerPrivacyAcceptationDate
    } = player;

    if (playerId === playerID) return null;


    const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;


    const updateTeamPlayerDataHandler = async (fieldName: string, fieldValue: string, fieldEditableStatusHandler: (s: boolean) => void) => {

        let error = false;
        const FormErrors: FormErrorsInterface = FormErrorsDefaults;

        switch (fieldName) {
            case "player_first_name":
                if (!isFirstName(fieldValue)) {
                    error = true;
                    FormErrors.playerFirstName = TEAM_PLAYER_FIRST_NAME_FIELD_ERROR;
                }
                break;
            case "player_last_name":
                if (!isLastName(fieldValue)) {
                    error = true;
                    FormErrors.playerLastName = TEAM_PLAYER_LAST_NAME_FIELD_ERROR;
                }
                break;
            case "player_social_id":
                if (!isSocialID(fieldValue)) {
                    error = true;
                    FormErrors.playerSocialID = TEAM_PLAYER_SOCIAL_ID_FIELD_ERROR;
                }
                break;
            case "player_email_address":
                if (!isEmail(fieldValue)) {
                    error = true;
                    FormErrors.playerEmailAddress = TEAM_PLAYER_EMAIL_ADDRESS_FIELD_ERROR;
                }
                break;
            case "player_number":
                if (+fieldValue < 0 || +fieldValue > 999) {
                    error = true;
                    FormErrors.playerNumber = TEAM_PLAYER_NUMBER_ADDRESS_FIELD_ERROR;
                }
                break;
        }

        setFormErrors(FormErrors);

        if (error) return;

        if (await updateTeamPlayerDataRequestHandler(fieldName, fieldValue)) {
            fieldEditableStatusHandler(false);
            updatePlayerTrigger(true);
            return UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_UPDATE_SUCCESS_MESSAGE });
        }

        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };


    const updateTeamPlayerDataRequestHandler = async (fieldName: string, fieldValue: string) => {

        const formData = new FormData();
        formData.append(fieldName, fieldValue);

        try {
            const response = await REST_API_REQUEST(
                `${API_URL}/teams/players/${playerId}/update-player-data/`, formData, "PATCH"
            );
            return response.ok;
        } catch (e) {}

        return false;

    };


    const emailMessageSenderHandler = async (messageType: "1" | "2") => {

        if (!sendEmailMessageConfirmStatus) {
            setSendEmailMessageConfirmStatus(true);
            return UseSetPopupMessage({ text: DEFAULT_SEND_MESSAGE_CONFIRMATION_MESSAGE });
        }

        setSendEmailMessageConfirmStatus(false);

        if (await emailMessageSenderRequestHandler(messageType)) {
            return UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_MESSAGE_SENT_MESSAGE });
        }

        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };

    const emailMessageSenderRequestHandler = async (messageType: "1" | "2") : Promise<boolean> => {

        const formData = new FormData();
        formData.append("email_type", messageType);

        try {
            const response = await REST_API_REQUEST(
                `${API_URL}/teams/players/${playerId}/send-email-to-player/`,
                formData, "POST"
            );
            return response.ok;
        } catch (e) {}

        return false;

    };


    const removePlayerFromTeamHandler = async () => {

        if (!removePlayerFromTeamConfirmStatus) {
            setRemovePlayerFromTeamConfirmStatus(true);
            return UseSetPopupMessage({ text: DEFAULT_DELETE_CONFIRMATION_MESSAGE })
        }

        setRemovePlayerFromTeamConfirmStatus(false);

        if (await removePlayerFromTeamRequestHandler()) {
            updatePlayerTrigger(true);
            return UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_DELETE_SUCCESS_MESSAGE });
        }

        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };

    const removePlayerFromTeamRequestHandler = async () : Promise<boolean> => {

        const formData = new FormData();
        formData.append("team_id", teamID.toString());

        try {
            try {
                const response = await REST_API_REQUEST(
                    `${API_URL}/teams/players/${playerId}/remove-player-from-team/`,
                    formData, "POST"
                );
                return response.ok;
            } catch (e) {}
        } catch (e) {}

        return false;

    };


    return (
        <Col xs={12} className={"captain-team-players-list__player captain-team-players-list__row" + (!playerActivatedStatus ? " not-activated" : "") + (!!playerActivatedStatus && (!playerRegulationsAcceptationDate || !playerPrivacyAcceptationDate) ? " regulations-not-accepted" : "")}>
            <Row className={"captain-team-players-list__row__content"}>

                <Col xs={12} lg={5} className={"player-name"}>
                    <Row className={"player-name-content"}>
                        <Col xs={6} className={"player-first-name"}>
                            <Row className={"player-first-name-content"}>
                                <FormTextFieldComponent
                                    fieldLabel={"Imię"}
                                    fieldName={"player_first_name"}
                                    fieldValue={teamPlayerFirstName}
                                    fieldValueHandler={changeTeamPlayerFirstName}
                                    fieldEditableStatus={false}
                                    fieldHyperLink={`/zawodnicy/${playerSlug}`}
                                    fieldError={playerFormErrors.playerFirstName}
                                    fieldSaveDataHandler={!playerActivatedStatus ? updateTeamPlayerDataHandler : undefined}
                                />
                            </Row>
                        </Col>
                        <Col xs={6} className={"player-last-name"}>
                            <Row className={"player-last-name-content"}>
                                <FormTextFieldComponent
                                    fieldLabel={"Nazwisko"}
                                    fieldName={"player_last_name"}
                                    fieldValue={teamPlayerLastName}
                                    fieldValueHandler={changeTeamPlayerLastName}
                                    fieldEditableStatus={false}
                                    fieldHyperLink={`/zawodnicy/${playerSlug}`}
                                    fieldError={playerFormErrors.playerLastName}
                                    fieldSaveDataHandler={!playerActivatedStatus ? updateTeamPlayerDataHandler : undefined}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={6} lg={4} className={"player-email"}>
                    <Row className={"player-email-content"}>
                        <FormTextFieldComponent
                            fieldLabel={"Email"}
                            fieldName={"player_email_address"}
                            fieldValue={teamPlayerEmailAddress}
                            fieldValueHandler={changeTeamPlayerEmailAddress}
                            fieldEditableStatus={false}
                            fieldError={playerFormErrors.playerEmailAddress}
                            fieldSaveDataHandler={!playerActivatedStatus ? updateTeamPlayerDataHandler : undefined}
                        />
                    </Row>
                </Col>

                <Col xs={6} lg={2} className={"player-number"}>
                    <Row className={"player-number-content"}>
                        <FormTextFieldComponent
                            fieldLabel={"Numer"}
                            fieldName={"player_number"}
                            fieldType={"number"}
                            fieldValue={teamPlayerNumber.toString()}
                            fieldValueHandler={changeTeamPlayerNumber}
                            fieldEditableStatus={false}
                            fieldError={playerFormErrors.playerNumber}
                            fieldSaveDataHandler={updateTeamPlayerDataHandler}
                        />
                    </Row>
                </Col>

                <Col xs={{span: 4, offset: 2}} lg={{ span: 1, offset: 0 }} className={"player-actions"}>
                    {!playerActivatedStatus &&
                    <span onClick={() => emailMessageSenderHandler("1")} className={"player-action send-mail-action" + (sendEmailMessageConfirmStatus ? " active" : "")} title={SEND_ACTIVATION_EMAIL_LABEL}>
                        <AiOutlineMail/>
                    </span>
                    }
                    {(!!playerActivatedStatus && (!playerRegulationsAcceptationDate || !playerPrivacyAcceptationDate)) &&
                    <span onClick={() => emailMessageSenderHandler("2")} className={"player-action send-mail-action" + (sendEmailMessageConfirmStatus ? " active" : "")} title={SEND_REGULATIONS_EMAIL_LABEL}>
                        <AiOutlineMail/>
                    </span>
                    }
                    {/*<span onClick={removePlayerFromTeamHandler} className={"player-action send-mail-action" + (removePlayerFromTeamConfirmStatus ? " active" : "")} title={REMOVE_PLAYER_FROM_TEAM_LABEL}>*/}
                    {/*    <AiFillDelete />*/}
                    {/*</span>*/}
                </Col>

                {(!playerActivatedStatus || !playerRegulationsAcceptationDate || !playerPrivacyAcceptationDate) &&
                <Col xs={12} className={"player-inactive-status-information"}>
                    {!playerActivatedStatus && <span>{PLAYER_NOT_ACTIVATED_STATUS}</span>}
                    {!!playerActivatedStatus && <span>{PLAYER_REGULATIONS_NOT_ACCEPTED_STATUS}</span>}
                </Col>
                }

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamPlayerComponent;
