import React from "react";
import {Col, Row} from "react-bootstrap";
import QuarterEventTimeComponent
    from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime/Time";
import QuarterEventTeamPointsComponent
    from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime/Points";
import {
    GameTeamsPointsInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types/Points";


import "./index.scss";


export interface QuarterEventPointsAndTimeComponentProps {
    eventTime: string | null;
    pointsEvent?: boolean;
    gamePoints?: GameTeamsPointsInterface;
    cssClasses?: string | string[];
}


export const QuarterEventPointsAndTimeComponent: React.FC<QuarterEventPointsAndTimeComponentProps> = ({
    eventTime, pointsEvent= false, gamePoints, cssClasses = ""
}) : JSX.Element | null => {


    if (cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    return (
        <Col xs={4} lg={2} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time" + (cssClasses ? ` ${cssClasses}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__content"}>

                <QuarterEventTeamPointsComponent
                    pointsNumber={gamePoints?.homeTeam || 0}
                    showPoints={!!pointsEvent}
                    isWinningStatus={gamePoints && gamePoints?.homeTeam > gamePoints?.awayTeam}
                    cssClasses={"home-team-points"}
                />

                <QuarterEventTimeComponent eventTime={eventTime} />

                <QuarterEventTeamPointsComponent
                    pointsNumber={gamePoints?.awayTeam || 0}
                    showPoints={!!pointsEvent}
                    isWinningStatus={gamePoints && gamePoints?.awayTeam > gamePoints?.homeTeam}
                    cssClasses={"away-team-points"}
                />

            </Row>
        </Col>
    );

};


export default QuarterEventPointsAndTimeComponent;
