import React, {useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import Dictionary from "@GlobalData/Localization/Dictionary";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";


import {Col, Row} from "react-bootstrap";


import PlayersTop5HeaderComponent from "@Components/Views/Pages/Games/Teams/Team/Players/Top/Header";
import PlayersTop5PlayersComponent from "@Components/Views/Pages/Games/Teams/Team/Players/Top/Players";


import "./index.scss";


import StatisticsType from "@GlobalData/Statistics/Types/Type";


export interface TeamPlayersTop5ComponentProps {
    teamID: number;
    teamLeague?: LeagueInterface | null;
    photoURL?: string | null;
}


export const TeamPlayersTop5Component: React.FC<TeamPlayersTop5ComponentProps> = ({
    teamID, teamLeague, photoURL
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        statistics_medium_label_text: STATISTICS_MEDIUM_LABEL_TEXT,
        statistics_summary_label_text: STATISTICS_SUMMARY_LABEL_TEXT
    } = Dictionary[activeLanguage];


    const [selectedStatisticsMainType, setSelectedStatisticsMainType] = useState<"MEDIUM" | "TOTAL">("MEDIUM");
    const [selectedStatisticsType, setSelectedStatisticsType] = useState<StatisticsType>("TPTS");


    if (!teamID) return null;


    return (
        <Col xs={12} lg={{span: 4, offset: !!photoURL ? 0 : 4}} className={"team-page__team-players__team-top5"}>
            <Row className={"team-players-top5-content"}>

                <Col xs={12} className={"team-top5-switcher"}>
                    <Row className={"team-players-top5-switcher-content"}>
                        <Col xs={4} className={"switcher-label"}>
                            <span>TOP 5</span>
                        </Col>
                        <Col xs={8} className={"switcher-buttons"}>
                            <span className={"switcher-button" + (selectedStatisticsMainType === "MEDIUM" ? " selected" : "")} onClick={() => setSelectedStatisticsMainType("MEDIUM")}>{STATISTICS_MEDIUM_LABEL_TEXT}</span>
                            <span>/</span>
                            <span className={"switcher-button" + (selectedStatisticsMainType === "TOTAL" ? " selected" : "")} onClick={() => setSelectedStatisticsMainType("TOTAL")}>{STATISTICS_SUMMARY_LABEL_TEXT}</span>
                        </Col>
                    </Row>
                </Col>

                <PlayersTop5HeaderComponent
                    selectedStatisticsType={selectedStatisticsType}
                    selectedStatisticsTypeHandler={setSelectedStatisticsType}
                />

                <PlayersTop5PlayersComponent
                    selectedStatisticsMainType={selectedStatisticsMainType}
                    selectedStatisticsType={selectedStatisticsType}
                    teamID={teamID}
                    teamLeague={teamLeague}
                />

            </Row>
        </Col>
    );

};


export default TeamPlayersTop5Component;
