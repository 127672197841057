import React, { useState } from "react";


import _ from "lodash";


import { Col, Row } from "react-bootstrap";

import { AiFillCaretDown } from "react-icons/all";


import LeaguesSwitcherLeagueComponent from "@ResultsPages/Switcher/Leagues/League";


import {
    LeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ResultsPageLeaguesSwitcherComponentProps {
    selectedLeagueID: number;
    selectedLeagueIDHandler(leagueID: number): void;
    Leagues: LeagueInterface[] | null;
}


export const ResultsPageLeaguesSwitcherComponent: React.FC<ResultsPageLeaguesSwitcherComponentProps> = ({
    selectedLeagueID, selectedLeagueIDHandler,
    Leagues
}) : JSX.Element | null => {


    const [leaguesListOpenStatus, setLeaguesListOpenStatus] = useState<boolean>(false);

    const changeSelectedLeagueHandler = (leagueID: number) => {
        setLeaguesListOpenStatus(false);
        selectedLeagueIDHandler(leagueID);
    };


    if (!Leagues || !selectedLeagueID) {
        return null;
    }


    const SelectedLeague = _.find(Leagues, { "league_id": selectedLeagueID });


    const LeaguesList = Leagues.filter(
        (League) => League !== SelectedLeague
    ).map((League, i) =>
        <LeaguesSwitcherLeagueComponent
            key={i}
            League={League}
            selectedLeagueIDHandler={changeSelectedLeagueHandler}
        />
    );


    let selectedLeagueName = "";

    if (SelectedLeague) {
        const { league_name: leagueName } = SelectedLeague;
        selectedLeagueName = leagueName;
    }


    return (
        <Col xs={6} lg={4} className={"leagues-switcher-component switcher-option-component leagues-schedules__leagues-and-rounds-switcher__leagues-switcher"}>
            <Row className={"leagues-switcher-component-content leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__content"}>

                {SelectedLeague &&
				<Col xs={12} className={"leagues-switcher-active-option switcher-active-option leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__league leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__league--selected-league"} onClick={() => LeaguesList?.length > 0 ? setLeaguesListOpenStatus(!leaguesListOpenStatus) : null }>
					<span className={"switcher-active-option-label league-label"}>{selectedLeagueName}</span>
                    {LeaguesList?.length > 0 &&
                    <span className={"switcher-active-option-icon league-icon"}>
						<AiFillCaretDown/>
					</span>
                    }
				</Col>
                }

                {leaguesListOpenStatus &&
                <Col xs={12} className={"switcher-options-list leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__leagues-list"}>
                    {LeaguesList}
				</Col>
                }

            </Row>
        </Col>
    );

};


export default ResultsPageLeaguesSwitcherComponent;
