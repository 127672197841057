import React from "react";


import { Col, Row } from "react-bootstrap";


import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import TeamCaptainTeamComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team";


import { LeagueTeamInterface } from "@Interfaces/Api";


export interface PlayerProfileTeamCaptainAreaComponentProps {
    playerID: number;
    captainedTeams?: LeagueTeamInterface[] | null;
    updatePlayerTrigger: (update: boolean) => void;
}


export const PlayerProfileTeamCaptainAreaComponent: React.FC<PlayerProfileTeamCaptainAreaComponentProps> = ({
    playerID, captainedTeams, updatePlayerTrigger
}): JSX.Element | null => {


    const CAPTAIN_ZONE_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_ZONE_LABEL");


    if (!captainedTeams || !captainedTeams?.length) return null;


    const CaptainedTeams = captainedTeams.map((Team, i) =>
        <TeamCaptainTeamComponent
            key={i}
            team={Team}
            playerID={playerID}
            updatePlayerTrigger={updatePlayerTrigger}
        />
    );


    return (
        <Col xs={12} className={"user-profile-page-data user-profile-captain-area"}>
            <Row className={"user-profile-page-data-content"}>

                <Col xs={12} className={"user-profile-page-data-header"}>
                    <span>{CAPTAIN_ZONE_LABEL}</span>
                </Col>

                {CaptainedTeams}

            </Row>
        </Col>
    );

};


export default PlayerProfileTeamCaptainAreaComponent;
