import React from "react";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import NumberFormat from "react-number-format";


import NO_AVATAR_IMAGE from "@Images/no-avatar.jpg";


import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


export interface LeaguesStatisticsTablePlayerComponentProps {
    place: number;
    player: PlayerLeagueStatisticsInterface;
    selectedStatisticsMainType: "MEDIUM" | "TOTAL";
}


export const LeaguesStatisticsTablePlayerComponent: React.FC<LeaguesStatisticsTablePlayerComponentProps> = ({
    place, player, selectedStatisticsMainType
}) : JSX.Element | null => {


    if (!player) return null;


    const {
        player_data: {
            player_slug: playerSlug,
            player_first_name: playerFirstName, player_last_name: playerLastName, player_profile_image: playerAvatarImage,
            player_team_data: playerTeam
        },
        player_score: playerScore
    } = player;


    const {
        team_name: playerTeamName, team_slug: playerTeamSlug
    } = playerTeam || {};


    return (
        <Col xs={12} className={"league-statistics-table-player league-statistics-table-player-component"}>
            <Row className={"league-statistics-table-player-content"}>

                <Col xs={1} className={"player-place"}>
                    <span>{place}</span>
                </Col>

                <Col xs={2} className={"player-image"}>
                    <NavLink to={`/zawodnicy/${playerSlug}`} title={``}>
                        <img src={playerAvatarImage || NO_AVATAR_IMAGE} alt={``}/>
                    </NavLink>
                </Col>

                <Col xs={6} className={"player-data"}>
                    <Row className={"player-name-data"}>
                        <Col xs={12} className={"player-name"}>
                            <NavLink to={`/zawodnicy/${playerSlug}`} title={``}>
                                <span>{playerFirstName} {playerLastName}</span>
                            </NavLink>
                        </Col>
                    </Row>
                    {!!playerTeamSlug &&
	                <Row className={"player-team-data"}>
		                <Col xs={12} className={"player-team"}>
			                <NavLink to={`/druzyny/${playerTeamSlug}`} title={``}>
				                <span>{playerTeamName}</span>
			                </NavLink>
		                </Col>
	                </Row>
                    }
                </Col>

                <Col xs={2} lg={3} className={"player-score"}>
                    <NumberFormat value={playerScore} decimalScale={selectedStatisticsMainType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                </Col>

            </Row>
        </Col>
    );


}


export default LeaguesStatisticsTablePlayerComponent;
