import React from "react";


import { Col } from "react-bootstrap";


import "./index.scss";


export interface ResultsListRoundHeaderComponentProps {
    leagueRoundLabel: string | null;
}


export const ResultsListRoundHeaderComponent: React.FC<ResultsListRoundHeaderComponentProps> = ({
    leagueRoundLabel
}) => {


    if (!leagueRoundLabel) {
        return null;
    }


    return (
        <Col xs={12} lg={2} className={"results-list-component-header leagues-schedules__league-rounds-list__league_round__header"}>
            <h4 className={"results-list-header"}>
                <span>{leagueRoundLabel}</span>
            </h4>
        </Col>
    );

};


export default ResultsListRoundHeaderComponent;
