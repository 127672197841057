import ApplicationStore from "@Store";


import ApplicationActionTypes from "@Store/Action-Types/Application";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


export type REST_API_REQUEST_TYPE = (
    URL: string,
    DATA?: FormData | null,
    METHOD?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    LOADER?: boolean,
    HEADERS?: HeadersInit,
) => Promise<Response>;


export const REST_API_REQUEST: REST_API_REQUEST_TYPE = async (
    URL, DATA, METHOD = "GET", LOADER = true, HEADERS
) => {

    let RESPONSE: Response;

    if (LOADER) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: true
        });
    }

    const REQUEST_INIT: RequestInit = {method: METHOD};

    if (!!METHOD && METHOD !== "GET") REQUEST_INIT.body = DATA;

    if (!!HEADERS) REQUEST_INIT.headers = {...REQUEST_INIT.headers, ...HEADERS};

    try {

        RESPONSE = await fetch(URL, REQUEST_INIT);

    } catch (e) {

        const RESPONSE_CONTENT: ErrorMessageInterface = {
            errorCode: 500, errorMessage: String(e)
        };

        RESPONSE = new Response(
            JSON.stringify(RESPONSE_CONTENT),
            {status: 500, statusText: String(e)}
        );

    }

    if (LOADER) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: false
        });
    }

    return RESPONSE;

};


export default REST_API_REQUEST;
