import React, { useEffect, useState } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import { UseLoggedUser } from "@States";

import { UseSetPopupMessageAction } from "@StatesActions";


import { UseRoute } from "@Hooks/Routes";


import {
    UseErrorMessage,
    UseFormFieldErrorMessage,
    UseFormsDictionaryPhrasesSet,
    UseNotAuthenticatedUserDictionaryPhrasesSet,
} from "@Hooks/Texts";


import { Redirect } from "react-router-dom";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";

import MessageComponent from "@MessagesElements/Content";

import {
    FormComponent,
    FormTextFieldComponent, FormSubmitFieldComponent
} from "@FormsElements";

import {
    FormFieldValueErrorInterface
} from "@CommonFormsTypes";


import "./index.scss";


export interface PasswordFormErrorsInterface {
    userPassword?: FormFieldValueErrorInterface | null;
    userPasswordRepeat?: FormFieldValueErrorInterface | null;
}


export interface PasswordSetPageComponentProps {
    location: any;
}


export const PasswordSetPageComponent: React.FC<PasswordSetPageComponentProps> = ({
    location
}): JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const DEFAULT_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT");

    const PASSWORD_SET_PAGE_TITLE = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_SET_PAGE_TITLE");
    const PASSWORD_SET_PAGE_MESSAGE_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_SET_PAGE_MESSAGE_TEXT");
    const PASSWORD_SET_PAGE_BUTTON_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_SET_PAGE_BUTTON_TEXT");

    const PASSWORD_NEW_LABEL_TEXT = UseFormsDictionaryPhrasesSet("PASSWORD_NEW");
    const PASSWORD_REPEAT_LABEL_TEXT = UseFormsDictionaryPhrasesSet("PASSWORD_REPEAT");

    const PASSWORD_NEW_ERROR_TEXT = UseFormFieldErrorMessage("PASSWORD_NEW");
    const PASSWORD_REPEAT_ERROR_TEXT = UseFormFieldErrorMessage("PASSWORD_REPEAT");


    const [FormErrors, setFormErrors] = useState<PasswordFormErrorsInterface | null>(null);

    const [passwordSetRequestStatus, setPasswordSetRequestStatus] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setFormErrors(null);
            setPasswordSetRequestStatus(false);
        };
    }, []);


    const [userPassword, setUserPassword] = useState<string>("");
    const [userPasswordRepeat, setUserPasswordRepeat] = useState<string>("");

    const changeUserPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValue = e.target.value;
        if (passwordValue.length > 7) {
            if (FormErrors?.userPassword) setFormErrors({...FormErrors, userPassword: null});
        }
        setUserPassword(passwordValue);
    };

    const changeUserPasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValue = e.target.value;
        if (passwordValue === userPassword) {
            if (FormErrors?.userPasswordRepeat) setFormErrors({...FormErrors, userPasswordRepeat: null});
        }
        setUserPasswordRepeat(passwordValue);
    };

    useEffect(() => {
        return () => {
            setUserPassword("");
            setUserPasswordRepeat("");
        };
    }, []);


    const ProfileRoute = UseRoute("PROFILE");
    const LoginRoute = UseRoute("LOGIN");

    const ApplicationUser = UseLoggedUser();

    if (!!ApplicationUser) return <Redirect to={ProfileRoute.routeURL} />;


    const PARAMS = location?.search.replaceAll("?", "");

    if (!PARAMS) return <Redirect to={`/${LoginRoute.routeURL}`} />;

    const USER_MAIL = PARAMS.split("&")[0].split("=")[1];
    const USER_TOKEN = PARAMS.split("&")[1].split("=")[1];

    if (!USER_MAIL || !USER_TOKEN || passwordSetRequestStatus) return <Redirect to={`/${LoginRoute.routeURL}`} />;


    const setPasswordSubmitHandler = async () => {

        const formErrors: PasswordFormErrorsInterface = {};

        if (userPassword.length < 8) formErrors.userPassword = PASSWORD_NEW_ERROR_TEXT;
        if (userPasswordRepeat !== userPassword) formErrors.userPasswordRepeat = PASSWORD_REPEAT_ERROR_TEXT;

        setFormErrors(formErrors);

        if (Object.entries(formErrors).length) return;

        await setPasswordRequestHandler();

    };


    const setPasswordRequestHandler = async () => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        if (!API_URL) return;

        const formData = new FormData();
        formData.append("player_email", USER_MAIL);
        formData.append("player_token", USER_TOKEN);
        formData.append("player_password", userPassword);

        try {

            const response = await REST_API_REQUEST(
                `${API_URL}/teams/players/set-player-password/`, formData, "POST"
            );

            if (response.ok) {
                return setPasswordSetRequestStatus(true);
            } else {
                UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_TEXT });
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_TEXT })
        }

    };


    return (
        <PageContainerComponent id={`set-password-page`} cssClasses={`password-set-page password-set-page-component`}>

            <DefaultPageHeaderComponent
                headerText={PASSWORD_SET_PAGE_TITLE}
            />

            <PageContentComponent cssClasses={"password-set-page__content"}>

                <FormComponent cssClasses={"user-form password-set-form"}>

                    <MessageComponent messageText={PASSWORD_SET_PAGE_MESSAGE_TEXT} />

                    <FormTextFieldComponent
                        fieldType={"password"}
                        fieldValue={userPassword}
                        fieldValueHandler={changeUserPassword}
                        fieldPlaceholder={PASSWORD_NEW_LABEL_TEXT}
                        fieldError={FormErrors?.userPassword}
                    />

                    <FormTextFieldComponent
                        fieldType={"password"}
                        fieldValue={userPasswordRepeat}
                        fieldValueHandler={changeUserPasswordRepeat}
                        fieldPlaceholder={PASSWORD_REPEAT_LABEL_TEXT}
                        fieldError={FormErrors?.userPasswordRepeat}
                    />

                    <FormSubmitFieldComponent
                        buttonText={PASSWORD_SET_PAGE_BUTTON_TEXT}
                        buttonClickHandler={setPasswordSubmitHandler}
                    />

                </FormComponent>

            </PageContentComponent>

        </PageContainerComponent>
        );

};


export default PasswordSetPageComponent;
