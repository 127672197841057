import React, {useEffect, useState} from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {Col, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import MessageComponent from "@Components/Elements/Messages/Content";

import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";
import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import PlayersTop5PlayerComponent from "@Components/Views/Pages/Games/Teams/Team/Players/Top/Players/Player";


import StatisticsType from "@GlobalData/Statistics/Types/Type";

import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";

import {UseLeaguesDictionaryPhrasesSet} from "@Hooks/Texts";


export interface PlayersTop5PlayersComponentProps {
    selectedStatisticsMainType: "MEDIUM" | "TOTAL";
    selectedStatisticsType: StatisticsType;
    teamID: number;
    teamLeague?: LeagueInterface | null;
}


export const PlayersTop5PlayersComponent: React.FC<PlayersTop5PlayersComponentProps> = ({
    selectedStatisticsMainType, selectedStatisticsType,
    teamID, teamLeague
}) : JSX.Element | null => {


    const LEAGUE_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUE.MAIN_URL;


    const NO_GAMES_STATISTICS_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_GAMES_STATISTICS_MESSAGE_TEXT");


    const [teamTop5Players, setTeamTop5Players] = useState<PlayerLeagueStatisticsInterface[] | null>(null);
    const [teamTop5PlayersError, setTeamTop5PlayersError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {
        if (!teamID || !teamLeague) return;
        const {league_id: leagueID} = teamLeague;
        const getTeamTop5Players = async () => {
            try {
                const response = await REST_API_REQUEST(
                    `${LEAGUE_API_URL}/${leagueID}/league_statistics/?statistics_type=${selectedStatisticsMainType}&sort_type=${selectedStatisticsType}&team_id=${teamID}&results_limit=5`
                );
                if (response.ok) {
                    const responseContent = await response.json();
                    setTeamTop5Players(responseContent);
                } else {
                    setTeamTop5PlayersError({errorCode: response.status, errorMessage: ""});
                }
            } catch (e) {
                setTeamTop5PlayersError({errorCode: 501, errorMessage: ""});
            }
        };
        getTeamTop5Players();
    }, [selectedStatisticsMainType, selectedStatisticsType, teamID, teamLeague, LEAGUE_API_URL]);

    useEffect(() => {
        return () => {
            setTeamTop5Players(null);
            setTeamTop5PlayersError(null);
        };
    }, []);


    if (!teamID || !teamLeague) return null;


    if (!!teamTop5PlayersError) return <ErrorMessageComponent messageText={teamTop5PlayersError.errorMessage} />;


    if (!teamTop5Players) return <SingleContentLoaderComponent loaderColor={`#17366E`} />;


    if (!teamTop5Players?.length) return <MessageComponent messageText={NO_GAMES_STATISTICS_MESSAGE_TEXT} cssClasses={"margin-top-15"} />;


    const Top5Players = teamTop5Players.map((Player, i) =>
        <PlayersTop5PlayerComponent
            key={i}
            selectedStatisticsMainType={selectedStatisticsMainType}
            player={Player}
            position={i + 1}
        />
    );


    return (
        <Col xs={9} lg={12} className={"team-players-top5-players"}>
            <Row className={"team-players-top5-players-content"}>

                {Top5Players}

            </Row>
        </Col>
    );


};


export default PlayersTop5PlayersComponent;
