export const API_ROOT_URL: string = "https://gzk-admin.plka.pl";
export const REST_API_ROOT_ENDPOINT_URL: string = `${API_ROOT_URL}/api/v1`;


export const API_LEAGUES_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/leagues/leagues/";
export const API_LEAGUE_ROUNDS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/leagues/leagues/{LEAGUE_ID}/league_rounds/";

export const API_ARTICLES_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/articles/list/";
export const API_ARTICLE_BY_SLUG_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/articles/list/article-by-slug/";

export const API_FILES_FOR_DOWNLOAD_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/content/files/groups/";
export const API_HOME_PAGE_SLIDES_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/content/home-page-slides/";

export const API_PARTNERS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/settings/sponsors/";
