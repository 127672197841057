import React, { useEffect } from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";


import LeaguesTablesLeaguesListComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Leagues";
import LeaguesTablesTableComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Table";


import "./index.scss";


const TEXTS: any = {
    PL: {hp_league_tables_header: "Tabele"},
    EN: {hp_league_tables_header: "Tables"}
};


export type HomePageLeaguesTablesComponentProps = {
    leaguesList: LeagueInterface[];
    selectedSeasonID: number | null;
    selectedSeasonIDHandler: (seasonID: number) => void;
    selectedLeagueID: number | null;
    selectedLeagueIDHandler(leagueID: number | null): void;
    selectedLeague: LeagueInterface | null;
    selectedLeagueHandler: (legue: LeagueInterface | null) => void;
};


export const HomePageLeaguesTablesComponent: React.FC<HomePageLeaguesTablesComponentProps> = ({
    leaguesList,
    selectedSeasonID, selectedSeasonIDHandler,
    selectedLeagueID = null, selectedLeagueIDHandler,
    selectedLeague, selectedLeagueHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        hp_league_tables_header: HP_LEAGUE_TABLES_HEADER
    } = TEXTS[activeLanguage];


    useEffect(() => {

        if (!leaguesList || !leaguesList?.length || !!selectedLeagueID) return;

        const {league_id: leagueID, league_season_id: leagueSeasonID} = leaguesList[0];

        selectedLeagueIDHandler(leagueID);
        selectedLeagueHandler(leaguesList[0]);
        selectedSeasonIDHandler(leagueSeasonID || 0);

    }, [leaguesList]);


    return (
        <Col xs={12} lg={!!leaguesList && !leaguesList?.length ? 12 : 8} className={"home-page-leagues-tables"}>
            <Row className={"leagues-tables-content"}>

                <Col as={"h3"} xs={12} className={"home-page-leagues-header"}>
                    <span>{HP_LEAGUE_TABLES_HEADER}</span>
                </Col>

                <LeaguesTablesLeaguesListComponent
                    leaguesList={leaguesList}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={selectedLeagueIDHandler}
                    selectedLeagueHandler={selectedLeagueHandler}
                />

                <LeaguesTablesTableComponent
                    selectedSeasonID={selectedSeasonID}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeague={selectedLeague}
                />

            </Row>
        </Col>
    );

};


export default HomePageLeaguesTablesComponent;
