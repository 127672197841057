const API_ROOT_URL: string = "https://gzk-admin.plka.pl";
const REST_API_ROOT_URL: string = `${API_ROOT_URL}/api/v1`;


export interface REST_API_ENDPOINTS_URLS_INTERFACE {
    API_ROOT_URL: string;
    REST_API_ROOT_URL: string;
    USER: {
        LOGIN_PAGE: {
            MAIN_URL: string;
            LOGIN_REQUEST: string;
        }
    };
    COMMON: {
        HOME_PAGE: {
            MAIN_URL: string
        },
        ARTICLES_PAGE: {
            MAIN_URL: string,
            ARTICLES_LIST: {MAIN_URL: string},
            ARTICLE: {MAIN_URL: string, BY_SLUG_URL: string},
        },
        PARTNERS_PAGE: {
            MAIN_URL: string;
        },
        CONTACT_PAGE: {
            MAIN_URL: string; SEND_FORM_URL: string;
        },
        FILES_PAGE: {
            MAIN_URL: string;
            GROUPS: {
                MAIN_URL: string
            }
        },
        SOCIALS: {
            MAIN_URL: string;
        },
    },
    GAMES: {
        LEAGUES: {
            SEASONS: {
                SEASONS: {MAIN_URL: string},
                SEASON: {MAIN_URL: string},
            },
            LEAGUES: {
                LEAGUES: {MAIN_URL: string},
                LEAGUE: {MAIN_URL: string, BY_SLUG_URL: string},
            },
            ROUNDS: {
                ROUNDS: {MAIN_URL: string},
                ROUND: {MAIN_URL: string},
            },
            GAMES: {
                GAMES: {
                    MAIN_URL: string,
                    RESULTS_BAR_URL: string
                },
                GAME: {MAIN_URL: string},
            },
        },
        TEAMS: {
            TEAMS: {MAIN_URL: string},
            TEAM: {MAIN_URL: string, BY_SLUG_URL: string}
        },
        PLAYERS: {}
    }
}


export const REST_API_ENDPOINTS_URLS: REST_API_ENDPOINTS_URLS_INTERFACE = {
    API_ROOT_URL: API_ROOT_URL,
    REST_API_ROOT_URL: REST_API_ROOT_URL,
    USER: {
        LOGIN_PAGE: {
            MAIN_URL: "/",
            LOGIN_REQUEST: "/",
        }
    },
    COMMON: {
        HOME_PAGE: {
            MAIN_URL: `/`
        },
        ARTICLES_PAGE: {
            MAIN_URL: `${REST_API_ROOT_URL}/articles/list`,
            ARTICLES_LIST: {MAIN_URL: `${REST_API_ROOT_URL}/articles/list`},
            ARTICLE: {
                MAIN_URL: `${REST_API_ROOT_URL}/articles/list`,
                BY_SLUG_URL: `${REST_API_ROOT_URL}/articles/list/article-by-slug`
            }
        },
        PARTNERS_PAGE: {
            MAIN_URL: `${REST_API_ROOT_URL}/settings/sponsors`
        },
        CONTACT_PAGE: {
            MAIN_URL: `${REST_API_ROOT_URL}/content/pages/get-page-by-type/?page_type=contact-page`,
            SEND_FORM_URL: `${REST_API_ROOT_URL}/`
        },
        FILES_PAGE: {
            MAIN_URL: `${REST_API_ROOT_URL}/`,
            GROUPS: {
                MAIN_URL: `${REST_API_ROOT_URL}/content/files/groups`
            }
        },
        SOCIALS: {
            MAIN_URL: `${REST_API_ROOT_URL}/settings/socials`,
        },
    },
    GAMES: {
        LEAGUES: {
            SEASONS: {
                SEASONS: {MAIN_URL: ``},
                SEASON: {MAIN_URL: ``},
            },
            LEAGUES: {
                LEAGUES: {MAIN_URL: `${REST_API_ROOT_URL}/leagues/leagues`},
                LEAGUE: {
                    MAIN_URL: `${REST_API_ROOT_URL}/leagues/leagues`,
                    BY_SLUG_URL: `${REST_API_ROOT_URL}/leagues/leagues/single-by-slug`
                },
            },
            ROUNDS: {
                ROUNDS: {MAIN_URL: ``},
                ROUND: {MAIN_URL: ``},
            },
            GAMES: {
                GAMES: {
                    MAIN_URL: `${REST_API_ROOT_URL}/leagues/games`,
                    RESULTS_BAR_URL: `${REST_API_ROOT_URL}/leagues/games/results-bar`
                },
                GAME: {MAIN_URL: ``},
            },
        },
        TEAMS: {
            TEAMS: {MAIN_URL: `${REST_API_ROOT_URL}/teams/teams`},
            TEAM: {
                MAIN_URL: `${REST_API_ROOT_URL}/teams/teams`,
                BY_SLUG_URL: `${REST_API_ROOT_URL}/teams/teams/team-by-slug`
            }
        },
        PLAYERS: {}
    }
};


export default REST_API_ENDPOINTS_URLS;
