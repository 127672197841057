import React from "react";


import {Row, Col} from "react-bootstrap";


import { IMAGE_PLACEHOLDER } from "@Images";


import "./index.scss";


export interface TeamPlayersPhotoComponentProps {
    photoURL?: string | null;
}


export const TeamPlayersPhotoComponent: React.FC<TeamPlayersPhotoComponentProps> = ({
    photoURL
}) : JSX.Element | null => {


    if (!photoURL) return null;


    return (
        <Col xs={12} lg={4} className={"team-page__team-players__team-photo"}>
            <Row as={"picture"} className={"team-players-photo-picture"}>
                {!!photoURL && <img src={photoURL} alt={``} />}
            </Row>
        </Col>
    );

};


export default TeamPlayersPhotoComponent;
