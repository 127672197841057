import React, {useEffect, useState} from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";


import {Redirect} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import PlayerProfileNavigationMenuComponent from "@Components/Views/Pages/User/Authenticated/Profile/Player/Menu";
import PlayerProfileContentComponent from "@Components/Views/Pages/User/Authenticated/Profile/Player/Content";
import UserFormalsAgreementsComponent from "@Components/Views/Pages/User/Authenticated/Profile/Formals";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


import PlayerProfileContentTypeType from "@Components/Views/Pages/User/Authenticated/Profile/Player/_ContentType";


export interface PlayerUserProfilePageComponentProps {
    playerID: number;
    playerToken: string;
}


export const PlayerUserProfilePageComponent: React.FC<PlayerUserProfilePageComponentProps> = ({
    playerID, playerToken
}) : JSX.Element | null => {


    const [Player, setPlayer] = useState<LeaguePlayerInterface | null>(null);
    const [PlayerError, setPlayerError] = useState<ErrorMessageInterface | null>(null);

    const [updatePlayerTrigger, setUpdatePlayerTrigger] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setPlayer(null);
            setPlayerError(null);
            setUpdatePlayerTrigger(false);
        }
    }, []);


    const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

    useEffect(() => {

        if (!API_URL) return setPlayerError({errorCode: 406, errorMessage: ""});

        if (!!Player && updatePlayerTrigger === false) return;

        const getPlayer = async () => {
            if (updatePlayerTrigger) setUpdatePlayerTrigger(false);
            try {
                const response = await REST_API_REQUEST(`${API_URL}/teams/players/${playerID}/player-profile-data/`);
                if (response.ok) {
                    setPlayer(await response.json());
                } else {
                    return setPlayerError({errorCode: response.status, errorMessage: ""});
                }
            } catch (e) {
                return setPlayerError({errorCode: 500, errorMessage: ""});
            }
        };

        getPlayer();

    }, [playerID, updatePlayerTrigger]);


    const [playerProfileActiveSubPage, setPlayerProfileActiveSubPage] = useState<PlayerProfileContentTypeType>("MAIN_DATA");


    useEffect(() => {

        if (!Player) return;

        const {
            player_regulations_acceptation_date: regulationsAcceptationDate,
            player_privacy_acceptation_date: privacyAcceptationDate
        } = Player;

        if (!regulationsAcceptationDate || !privacyAcceptationDate) {
            setPlayerProfileActiveSubPage("FORMALS");
        }

    }, [Player]);


    if (!playerID) return <Redirect to={"/"} />;


    if (!!PlayerError) return <ErrorMessageComponent messageText={PlayerError.errorMessage} />;


    if (!Player) return <MainContentLoaderComponent />;


    const {
        player_regulations_acceptation_date: regulationsAcceptationDate,
        player_privacy_acceptation_date: privacyAcceptationDate
    } = Player;


    if (!regulationsAcceptationDate || !privacyAcceptationDate) {
        return (
            <UserFormalsAgreementsComponent
                userID={playerID}
                userRegulationAcceptedDate={regulationsAcceptationDate}
                userPrivacyAcceptedDate={privacyAcceptationDate}
                updateUserTrigger={setUpdatePlayerTrigger}
            />
        );
    }


    const {player_captained_teams: captainedTeams} = Player;
    const captainStatus = !!captainedTeams && captainedTeams?.length > 0;


    return (
        <Col xs={12} className={"user-profile-page-content"}>
            <Row className={"user-profile-page-content-content"}>

                <PlayerProfileNavigationMenuComponent
                    selectedSubPage={playerProfileActiveSubPage}
                    selectedSubPageHandler={setPlayerProfileActiveSubPage}
                    playerCaptainStatus={captainStatus}
                />

                <PlayerProfileContentComponent
                    player={Player}
                    activeContent={playerProfileActiveSubPage}
                    updatePlayerTrigger={setUpdatePlayerTrigger}
                />

            </Row>
        </Col>
    );

};


export default PlayerUserProfilePageComponent;
