import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import ROUTER from "@Router";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import NumberFormat from "react-number-format";


import noAvatarImage from "@Images/no-avatar.jpg";


const TEXTS: any = {
    PL: {game_word_text: "mecz"}, EN: {game_word_text: "game"},
};


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import StatisticsTypes from "@GlobalData/Statistics/Types";

import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";
import LeagueTeamInterface, {
    LeagueTeamLinkInterface
} from "../../../../../../../../../Services/Interfaces/Api/Teams/Team";
import {LeaguePlayerTeamInterface} from "@Interfaces/Api";


interface LeaguesStatisticsTablePlayerComponentProps {
    index: number;
    selectedLeagueID: number;
    selectedStatisticsType: StatisticsType;
    playerData: PlayerLeagueStatisticsInterface;
}


export const LeaguesStatisticsTablePlayerComponent: React.FC<LeaguesStatisticsTablePlayerComponentProps> = ({
    index = 0, selectedLeagueID,
    selectedStatisticsType = "TPTS",
    playerData
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {game_word_text: GAME_WORD_TEXT} = TEXTS[activeLanguage];


    if (!playerData || !selectedStatisticsType) return null;


    const {
        player_score: playerScore,
        player_data: {
            player_id: playerID, player_slug: playerSlug,
            player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
            player_profile_image: playerProfileImage,
            player_pseudonymization_status: playerPseudonymizationStatus,
            player_main_team: PlayerMainTeam, player_teams: PlayerTeams
        },
    } = playerData;


    let PlayerTeam: LeaguePlayerTeamInterface | LeagueTeamLinkInterface | null = null;

    if (PlayerMainTeam) {

        const { team_leagues_list: teamLeaguesList } = PlayerMainTeam;

        if (teamLeaguesList) {
            for (let League of teamLeaguesList) {
                const { league_id: leagueID } = League;
                if (selectedLeagueID === leagueID) {
                    PlayerTeam = PlayerMainTeam;
                    break;
                }
            }
        }

    }

    if (!PlayerTeam && PlayerTeams) {

        for (let Team of PlayerTeams) {

            const { team_leagues_list: teamLeaguesList } = Team;

            if (teamLeaguesList) {
                for (let League of teamLeaguesList) {
                    const { league_id: leagueID } = League;
                    if (selectedLeagueID === leagueID) {
                        PlayerTeam = Team;
                        break;
                    }
                }
            }

            if (PlayerTeam) break;

        }

    }


    if (!PlayerTeam) {
        PlayerTeam = { team_name: "", team_slug: "" };
    }


    if (!playerID) return null;


    const playerName = playerPseudonymizationStatus ? `"${playerNickName}"` : `${playerFirstName} ${playerLastName}`;


    const {
        team_name: teamName, team_slug: teamSlug
    } = PlayerTeam;


    const {
        GAMES: {
            PLAYERS: {PLAYER: PLAYER_ROUTE},
            TEAMS: {TEAM: TEAM_ROUTE}
        }
    } = ROUTER[activeLanguage];


    return (
        <Col xs={12} className={"statistics-table-player"}>
            <Row className={"statistics-table-player-content"}>

                <Col xs={1} className={"statistics-table-position"}>
                    <span>{index + 1}</span>
                </Col>

                <Col xs={8} lg={7} className={"statistics-table-player-data"}>
                    <Row className={"player-data-content"}>
                        <Col xs={3} lg={3} className={"player-avatar"}>
                            <NavLink to={`${PLAYER_ROUTE.URL}/${playerSlug}`} title={PLAYER_ROUTE.TITLE}>
                                <img src={playerProfileImage || noAvatarImage} alt={""} />
                            </NavLink>
                        </Col>
                        <Col xs={9} lg={9} className={"player-information"}>
                            <Row className={"player-name-data"}>
                                <Col xs={12} className={"player-name"}>
                                    <NavLink to={`${PLAYER_ROUTE.URL}/${playerSlug}`} title={PLAYER_ROUTE.TITLE}>
                                        <span>{playerName}</span>
                                    </NavLink>
                                </Col>
                            </Row>
                            <Row className={"player-team-data"}>
                                {!!teamSlug && !!teamName &&
                                <Col xs={12} className={"player-team-name"}>
                                    <NavLink to={`${TEAM_ROUTE.URL}/${teamSlug}`} title={TEAM_ROUTE.TITLE}>
                                        <span>{teamName}</span>
                                    </NavLink>
                                </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={3} lg={4} className={"statistics-table-data"}>
                    <Row className={"statistics-table-data-content"}>
                        <Col xs={5} className={"statistics-data-value"}>
                            <NumberFormat value={playerScore} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        </Col>
                        <Col xs={7} className={"statistics-data-label"}>
                            <span>{StatisticsTypes[selectedStatisticsType][activeLanguage].label} / {GAME_WORD_TEXT}</span>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default LeaguesStatisticsTablePlayerComponent;
