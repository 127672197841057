import InformationMessagesInterface from "@Dictionary/Messages/Info/types";


import {
    DEFAULT_SEND_MESSAGE_CONFIRMATION_PL, DEFAULT_SEND_MESSAGE_CONFIRMATION_EN,
    DEFAULT_DELETE_CONFIRMATION_PL, DEFAULT_DELETE_CONFIRMATION_EN
} from "@Dictionary/Messages/Info/consts";


export const InformationMessages: InformationMessagesInterface = {
    PL: {
        DEFAULT: "",
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_RESPONSE: "",
        DEFAULT_UPDATE_DATA_RESPONSE: "",
        DEFAULT_SEND_MESSAGE_CONFIRMATION: DEFAULT_SEND_MESSAGE_CONFIRMATION_PL,
        DEFAULT_DELETE_CONFIRMATION: DEFAULT_DELETE_CONFIRMATION_PL,
    },
    EN: {
        DEFAULT: "",
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_RESPONSE: "",
        DEFAULT_UPDATE_DATA_RESPONSE: "",
        DEFAULT_SEND_MESSAGE_CONFIRMATION: DEFAULT_SEND_MESSAGE_CONFIRMATION_EN,
        DEFAULT_DELETE_CONFIRMATION: DEFAULT_DELETE_CONFIRMATION_EN,
    }
};


export default InformationMessages;
