import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { NavLink } from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import { LeagueTeamInterface } from "@Interfaces/Api";


import "./index.scss";


type GameSummaryTeamPointsProps = {
    GameTeam: LeagueTeamInterface;
    gameTeamPoints: number;
    gameTeamType: "HOME" | "AWAY";
    gameTeamResultType: "WIN" | "LOSE" | "DRAW";
};


export const GameSummaryTeamPointsComponent: React.FC<GameSummaryTeamPointsProps> = ({
    GameTeam, gameTeamPoints,
    gameTeamType, gameTeamResultType = "DRAW"
}) : JSX.Element | null => {


    const TEAM_PAGE_ROUTE = UseRoute("TEAMS");

    const {
        routeTitle: TEAMS_ROUTE_TITLE, routeURL: TEAMS_ROUTE_URL
    } = TEAM_PAGE_ROUTE;


    if (!GameTeam) return null;


    const { team_name: teamName, team_slug: teamSlug } = GameTeam;


    let CSS_CLASSES = "game-result-team-points game-result-team-points-component";

    if (gameTeamType === "HOME") CSS_CLASSES += " home-team-points";
    if (gameTeamType === "AWAY") CSS_CLASSES += " away-team-points";

    if (gameTeamResultType === "WIN") CSS_CLASSES += " game-winner";


    return (
        <Col xs={4} className={CSS_CLASSES}>
            <Row className={"game-result-team-points-content"}>

                <Col xs={12} className={"team-name"}>
                    <NavLink to={`/${TEAMS_ROUTE_URL}/${teamSlug}`} title={TEAMS_ROUTE_TITLE}>
                        <span>{teamName}</span>
                        <span className={"bg"}></span>
                    </NavLink>
                </Col>

                <Col xs={12} className={"team-points"}>
                    <span>{gameTeamPoints}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameSummaryTeamPointsComponent;
