import React from "react";


import { Col } from "react-bootstrap";

import { AiFillCaretDown } from "react-icons/all";


import getLeagueRoundLabel from "@Services/Utilities/Games/Leagues/Rounds/Rounds/GetLeagueRoundLabel";


import { LeagueRoundInterface } from "@Interfaces/Api";


import "./index.scss";


export interface RoundsSwitcherSelectedRoundComponentProps {
    SelectedRound: LeagueRoundInterface | null;
    selectedRoundIDChangeHandler: (selectedRoundID: number) => void;
}


export const RoundsSwitcherSelectedRoundComponent: React.FC<RoundsSwitcherSelectedRoundComponentProps> = ({
    SelectedRound, selectedRoundIDChangeHandler
}) => {


    let selectedRoundLabel = "-";

    if (SelectedRound) {
        selectedRoundLabel = getLeagueRoundLabel(SelectedRound);
    }


    return (
        <Col xs={12} className={"rounds-switcher-active-option switcher-active-option leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round leagues-schedules__leagues-and-rounds-switcher__rounds-switcher__round--selected-round"} onClick={() => selectedRoundIDChangeHandler(-1)}>
            <span className={"switcher-active-option-label round-label"}>{selectedRoundLabel}</span>
            <span className={"switcher-active-option-icon round-icon"}>
                <AiFillCaretDown/>
            </span>
        </Col>
    );

};


export default RoundsSwitcherSelectedRoundComponent;
