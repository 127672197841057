import { UseErrorMessage } from "@Hooks/Texts";

import REST_API_REQUEST from "@Services/HTTP/Requests/Request";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


export type _getDataFromApiType = (
    ApiEndpointURL: string,
    successResponseHandler: (objects: any | any[] | null) => void,
    errorResponseHandler: (Error: ErrorMessageInterface | null) => void,
    requestData?: FormData | null,
    requestMethod?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
    // externalCallbackFunction: ((...args: any[]) => void) | null,
) => Promise<boolean>;


export const _getDataFromApi: _getDataFromApiType = async (
    ApiEndpointURL,
    successResponseHandler, errorResponseHandler,
    requestData = null, requestMethod = "GET"
    // externalCallbackFunction = null
) => {


    if (!ApiEndpointURL) {
        errorResponseHandler(
            { errorCode: 403, errorMessage: "" }
        );
        return false;
    }

    try {

        const response = await REST_API_REQUEST(ApiEndpointURL, requestData, requestMethod);

        if (response.ok) {
            errorResponseHandler(null);
            successResponseHandler(await response.json());
            // if (externalCallbackFunction) {
            //     externalCallbackFunction();
            // }
            return true;
        } else {
            errorResponseHandler(
                { errorCode: response.status, errorMessage: "" }
            );
        }

    } catch (e) {
        errorResponseHandler(
            { errorCode: 500, errorMessage: "" }
        );
    }

    return false;

};


export default _getDataFromApi;
