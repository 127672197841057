import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import REST_API_REQUEST from "@Services/HTTP/Requests/Request";

import {Col, Row} from "react-bootstrap";

import {AiFillCloseCircle} from "react-icons/all";


import {FormSubmitFieldComponent, FormTextFieldComponent} from "@FormsElements";

import SuccessMessageComponent from "@Components/Elements/Messages/Message/Success";


import {isEmail} from "@Services/Utilities/Tools";


import DictionaryType from "@GlobalData/Localization/Dictionary/_Types";
import Dictionary from "@GlobalData/Localization/Dictionary";


import "./index.scss";
import FormFieldValueErrorInterface from "@Components/Elements/Forms/_Common/_Types/Errors/ValueError";
import NewTeamPlayerAddTypes from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/AddPlayer/Switcher/Types";
import MessageComponent from "@MessagesElements/Content";


const SuccessMessages: DictionaryType = {
    PL: {
        player_successfully_registered_message: "Zawodnik został zarejestrowany",
    },
    EN: {
        player_successfully_registered_message: "Player Successfully Registered",
    }
};

const ErrorMessages: DictionaryType = {
    PL: {
        first_name_too_short_message: "Zbyt krótkie imię",
        last_name_too_short_message: "Zbyt krótkie nazwisko",
        email_address_incorrect_message: "Niepoprawny Adres Email",
        email_address_exists_message: "Email przypisany do innego zawodnika",
        social_id_incorrect_message: "Podany Pesel jest nieprawidłowy",
        social_id_exists_message: "Pesel przypisany do innego zawodnika",
        update_request_undefined_error: "Wystąpił nieokreślony błąd"
    },
    EN: {
        first_name_too_short_message: "Name too short",
        last_name_too_short_message: "Surname too short",
        email_address_incorrect_message: "Incorrect Email",
        email_address_exists_message: "Email already registered",
        social_id_incorrect_message: "Incorrect Pesel",
        social_id_exists_message: "Pesel already registered",
        update_request_undefined_error: "Undefined Error"
    }
};

const ComponentTexts: DictionaryType = {
    PL: {
        add_new_player_text: "Dodaj nowego zawodnika",
    },
    EN: {
        add_new_player_text: "Add new player",
    }
};


export interface TeamCaptainAddTeamPlayerComponentProps {
    teamCaptainID: number;
    teamCaptainTeamID: number;
    addPlayerShowStatusHandler: (status: boolean) => void;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainAddTeamPlayerComponent: React.FC<TeamCaptainAddTeamPlayerComponentProps> = ({
    teamCaptainID, teamCaptainTeamID,
    addPlayerShowStatusHandler, updatePlayerTrigger
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        word_first_name_text: WORD_FIRST_NAME_TEXT, word_last_name_text: WORD_LAST_NAME_TEXT,
        word_email_text: WORD_EMAIL_TEXT,
        word_add_text: WORD_ADD_TEXT, word_abort_text: WORD_ABORT_TEXT
    } = Dictionary[activeLanguage];

    const {
        add_new_player_text: ADD_NEW_PLAYER_TEXT
    } = ComponentTexts[activeLanguage];

    const {
        player_successfully_registered_message: PLAYER_SUCCESSFULLY_REGISTERED_MESSAGE
    } = SuccessMessages[activeLanguage];

    const {
        first_name_too_short_message: FIRST_NAME_TOO_SHORT_MESSAGE,
        last_name_too_short_message: LAST_NAME_TOO_SHORT_MESSAGE,
        email_address_incorrect_message: EMAIL_ADDRESS_INCORRECT_MESSAGE,
        email_address_exists_message: EMAIL_ADDRESS_EXISTS_MESSAGE,
        social_id_exists_message: SOCIAL_ID_EXISTS_MESSAGE,
        update_request_undefined_error: UPDATE_REQUEST_UNDEFINED_ERROR
    } = ErrorMessages[activeLanguage];


    interface NewPlayerFormErrorsInterface {
        newPlayerFirstName?: FormFieldValueErrorInterface | null;
        newPlayerLastName?: FormFieldValueErrorInterface | null;
        newPlayerEmailAddress?: FormFieldValueErrorInterface | null;
        newPlayerRequest?: FormFieldValueErrorInterface | null;
    }


    const [newTeamPlayerAddType, setNewTeamPlayerAddType] = useState<NewTeamPlayerAddTypes>(NewTeamPlayerAddTypes.NEW);


    const [newPlayerFormErrors, setNewPlayerFormErrors] = useState<NewPlayerFormErrorsInterface>({});


    const [newPlayerFirstName, setNewPlayerFirstName] = useState<string>("");
    const [newPlayerLastName, setNewPlayerLastName] = useState<string>("");

    const changeNewPlayerFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 2 && newPlayerFormErrors?.newPlayerFirstName) {
            setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerFirstName: null});
        }
        setNewPlayerFirstName(value);
    };

    const changeNewPlayerLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 2 && newPlayerFormErrors?.newPlayerLastName) {
            setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerLastName: null});
        }
        setNewPlayerLastName(value);
    };

    const [newPlayerEmailAddress, setNewPlayerEmailAddress] = useState<string>("");

    const changeNewPlayerEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isEmail(value) && newPlayerFormErrors?.newPlayerEmailAddress) {
            setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerEmailAddress: null});
        }
        setNewPlayerEmailAddress(value);
    };

    useEffect(() => {
        return () => {
            setNewPlayerFirstName("");
            setNewPlayerLastName("");
            setNewPlayerEmailAddress("");
            setNewPlayerFormErrors({});
        };
    }, []);


    const [newPlayerRegistrationSuccessStatus, setNewPlayerRegistrationSuccessStatus] = useState<boolean>(false);

    useEffect(() => {
        if (newPlayerRegistrationSuccessStatus) {
            setTimeout(() => addPlayerShowStatusHandler(false), 2000);
        }
    }, [newPlayerRegistrationSuccessStatus]);

    useEffect(() => {
        return () => {
            setNewPlayerRegistrationSuccessStatus(false);
            setNewTeamPlayerAddType(NewTeamPlayerAddTypes.NEW);
        }
    }, [])

    if (newPlayerRegistrationSuccessStatus) return <SuccessMessageComponent messageText={PLAYER_SUCCESSFULLY_REGISTERED_MESSAGE} />;


    if (!teamCaptainID || !teamCaptainTeamID) return null;


    const addPlayerSubmitHandler = async () => {

        const formErrors: any = {};

        if (newPlayerFirstName.length < 3) formErrors.newPlayerFirstName = {errorMessage: FIRST_NAME_TOO_SHORT_MESSAGE};
        if (newPlayerLastName.length < 3) formErrors.newPlayerLastName = {errorMessage: LAST_NAME_TOO_SHORT_MESSAGE};
        if (!isEmail(newPlayerEmailAddress)) formErrors.newPlayerEmailAddress = {errorMessage: EMAIL_ADDRESS_INCORRECT_MESSAGE};

        setNewPlayerFormErrors(formErrors);

        if (Object.entries(formErrors).length) return;

        await addPlayerRequestHandler();

    };


    const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

    const addPlayerRequestHandler = async () => {

        if (!API_URL) return;

        const formData = new FormData();

        formData.append("player_email_address", newPlayerEmailAddress);
        formData.append("player_first_name", newPlayerFirstName);
        formData.append("player_last_name", newPlayerLastName);
        formData.append("player_team_id", teamCaptainTeamID.toString());

        if (newTeamPlayerAddType === "EXISTING") {
            formData.append("existing_player", "1");
        }

        try {

            const response = await REST_API_REQUEST(
                `${API_URL}/teams/players/register-new-player/`, formData, "POST"
            );

            if (response.ok) {
                setNewPlayerRegistrationSuccessStatus(true);
                updatePlayerTrigger(true);
            } else {
                switch (response.status) {
                    case 406:
                        setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerEmailAddress: {errorCode: 46, errorMessage: EMAIL_ADDRESS_EXISTS_MESSAGE}});
                        setNewTeamPlayerAddType(NewTeamPlayerAddTypes.EXISTING);
                        break;
                    default:
                        setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerEmailAddress: {errorMessage: UPDATE_REQUEST_UNDEFINED_ERROR}});
                }
            }

        } catch (e) {
            setNewPlayerFormErrors({...newPlayerFormErrors, newPlayerEmailAddress: {errorMessage: UPDATE_REQUEST_UNDEFINED_ERROR}});
        }

    };


    const mainComponentCssClass = [
        "player-profile", "captain-zone", "team", "players", "add-player"
    ].join("__")


    return (
        <Col xs={12} className={mainComponentCssClass}>
            <Row className={`${mainComponentCssClass}__content`}>

                <Col xs={12} className={`${mainComponentCssClass}__header`}>
                    <span className={"header-label"}>{ADD_NEW_PLAYER_TEXT}</span>
                    <span onClick={() => addPlayerShowStatusHandler(false)} className={"header-action"} title={WORD_ABORT_TEXT}>
                        <AiFillCloseCircle />
                    </span>
                </Col>

                {/*<AddTeamPlayerTypeSwitcherComponent*/}
                {/*    newTeamPlayerAddType={newTeamPlayerAddType}*/}
                {/*    newTeamPlayerAddTypeHandler={setNewTeamPlayerAddType}*/}
                {/*/>*/}

                <FormTextFieldComponent
                    fieldValue={newPlayerFirstName}
                    fieldValueHandler={changeNewPlayerFirstName}
                    fieldPlaceholder={WORD_FIRST_NAME_TEXT}
                    fieldError={newPlayerFormErrors.newPlayerFirstName}
                    lg={4}
                />

                <FormTextFieldComponent
                    fieldValue={newPlayerLastName}
                    fieldValueHandler={changeNewPlayerLastName}
                    fieldPlaceholder={WORD_LAST_NAME_TEXT}
                    fieldError={newPlayerFormErrors.newPlayerLastName}
                    lg={4}
                />

                <FormTextFieldComponent
                    fieldValue={newPlayerEmailAddress}
                    fieldValueHandler={changeNewPlayerEmailAddress}
                    fieldPlaceholder={WORD_EMAIL_TEXT}
                    fieldError={newPlayerFormErrors.newPlayerEmailAddress}
                    fieldCssClasses={"default-padding"}
                    lg={4}
                />

                {newPlayerFormErrors.newPlayerEmailAddress?.errorCode === 46 &&
                <MessageComponent
                    messageText={`
                       Podany adres email jest już przypisany do zarejestrowanego zawodnika.<br/>
                       Czy na pewno chcesz dodać ten profil do swojej drużyny?
                   `}
                    cssClasses={"margin-bottom-20 bordered padding-10"}
                    messageType={"warning"}
                />
                }

                <FormSubmitFieldComponent
                    buttonText={WORD_ADD_TEXT}
                    buttonClickHandler={() => addPlayerSubmitHandler()}
                />

            </Row>
        </Col>
    );

};


export default TeamCaptainAddTeamPlayerComponent;
