import React from "react";


import { Col, Row } from "react-bootstrap";


import { UseFormsDictionaryPhrasesSet } from "@Hooks/Texts/Sets/Forms/Fields/Field";


import TeamCaptainTeamPlayerComponent from "@Components/Views/Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/PlayersList/Player";


import "./index.scss";


import { LeaguePlayerInterface } from "@Interfaces/Api";


export interface TeamCaptainTeamPlayersComponentProps {
    teamID: number;
    playerID: number;
    teamPlayers?: LeaguePlayerInterface[] | null;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainTeamPlayersComponent: React.FC<TeamCaptainTeamPlayersComponentProps> = ({
    playerID, teamID,
    teamPlayers,
    updatePlayerTrigger
}) : JSX.Element | null => {


    const TEAM_PLAYER_FIRST_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("FIRST_NAME");
    const TEAM_PLAYER_LAST_NAME_FIELD_LABEL = UseFormsDictionaryPhrasesSet("LAST_NAME");
    const TEAM_PLAYER_SOCIAL_ID_FIELD_LABEL = UseFormsDictionaryPhrasesSet("SOCIAL_ID");
    const TEAM_PLAYER_EMAIL_ADDRESS_FIELD_LABEL = UseFormsDictionaryPhrasesSet("EMAIL_ADDRESS");
    const TEAM_PLAYER_NUMBER_FIELD_LABEL = UseFormsDictionaryPhrasesSet("NUMBER");


    if (!teamPlayers) return null;


    const TeamPlayers = teamPlayers.map((Player, i) =>
        <TeamCaptainTeamPlayerComponent
            key={i}
            teamID={teamID}
            playerID={playerID}
            player={Player}
            updatePlayerTrigger={updatePlayerTrigger}
        />
    );


    return (
        <Col xs={12} className={"captain-team-players-list"}>
            <Row className={"captain-team-players-list-content"}>

                <Col className={"captain-team-players-list__header captain-team-players-list__row"}>
                    <Row className={"captain-team-players-list__row__content"}>
                        <Col xs={5} className={"player-name"}>
                            <span>{TEAM_PLAYER_FIRST_NAME_FIELD_LABEL} {TEAM_PLAYER_LAST_NAME_FIELD_LABEL}</span>
                        </Col>
                        <Col xs={4} className={"player-email"}>
                            <span>{TEAM_PLAYER_EMAIL_ADDRESS_FIELD_LABEL}</span>
                        </Col>
                        <Col xs={2} className={"player-email"}>
                            <span>{TEAM_PLAYER_NUMBER_FIELD_LABEL}</span>
                        </Col>
                    </Row>
                </Col>

                {TeamPlayers}

            </Row>
        </Col>
    )

};


export default TeamCaptainTeamPlayersComponent;
