export const validateSocialID = (socialID: string | number) : boolean => {

    if (!socialID) return false;
    console.log(socialID);
    if (typeof socialID === "number") socialID = socialID.toString();

    let year = parseInt(socialID.substring(0, 2), 10);
    let month = parseInt(socialID.substring(2, 4), 10) - 1;
    const day = parseInt(socialID.substring(4, 6), 10);

    if (month > 80) {
        year = year + 1800;
        month = month - 80;
    } else if (month >= 60) {
        year = year + 2200;
        month = month - 60;
    } else if (month >= 40) {
        year = year + 2100;
        month = month - 40;
    } else if (month >= 20) {
        year = year + 2000;
        month = month - 20;
    } else {
        year += 1900;
    }


    if (!(month >= 0 && month < 12 && day > 0 && day < 32)) {
        return false;
    }

    const weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    let sum = 0;

    for (let i = 0; i < weights.length; i++) {
        sum += (parseInt(socialID.substring(i, i + 1), 10) * weights[i]);
    }

    sum = sum % 10;

    return (sum === parseInt(socialID.substring(10, 11), 10));

}


export default validateSocialID;
