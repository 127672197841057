import React, { useEffect, useState } from "react";


import {
    API_ARTICLES_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _getDataFromApi from "@Services/API/Requests";


import {
    UseErrorMessage, UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    PageContentComponent
} from "@ContentElements";

import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import MessageComponent from "@Components/Elements/Messages/Content";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import ArticlesListArticleComponent from "@Components/Views/Pages/Common/ArticlesPage/ArticlesList/Article";


import ArticleInterface from "@Interfaces/Api/_Common/Articles/Article";


import "./index.scss";


export interface ArticlesListComponentProps {}


export const ArticlesListComponent: React.FC<ArticlesListComponentProps> = () : JSX.Element | null => {


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const NEWS_PAGE_NO_NEWS_MESSAGE_TEXT = UseGlobalsDictionaryPhrasesSet("NEWS_PAGE_NO_NEWS_MESSAGE_TEXT");


    const [Articles, setArticles] = useState<ArticleInterface[] | null>(null);
    const [ArticlesError, setArticlesError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const getArticles = async () => {
            await _getDataFromApi(
                API_ARTICLES_LIST_ENDPOINT_URL,
                setArticles, setArticlesError
            )
        };

        getArticles();

    }, []);


    useEffect(() => {
        return () => {
            setArticles(null);
            setArticlesError(null);
        }
    }, []);


    if (ArticlesError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR_MESSAGE} />;
    }


    if (!Articles) {
        return <MainContentLoaderComponent loaderColor={"#17366E"} />;
    }


    const ArticlesList = Articles.map((article, i) =>
        <ArticlesListArticleComponent key={i} Article={article} />
    );


    if (!ArticlesList?.length) {
        return <MessageComponent messageText={NEWS_PAGE_NO_NEWS_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;
    }


    return (
        <PageContentComponent cssClasses={"articles-list-page__articles-list"}>

            {ArticlesList}

        </PageContentComponent>
    );


};


export default ArticlesListComponent;
