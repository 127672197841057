import React, { useEffect, useState } from "react";


import { Col, Row } from "react-bootstrap";

import { AiOutlinePlus } from "react-icons/all";


import { UseTeamsDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import TeamCaptainAddTeamPlayerComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/AddPlayer";
import TeamCaptainTeamPlayersComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/PlayersList";
import TeamCaptainTeamDataComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/TeamData";
import TeamCaptainTeamFilesComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files";


import { LeagueTeamInterface } from "@Interfaces/Api";


import "./index.scss";


export interface TeamCaptainTeamComponentProps {
    playerID: number;
    team: LeagueTeamInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainTeamComponent: React.FC<TeamCaptainTeamComponentProps> = ({
    playerID, team, updatePlayerTrigger
}) : JSX.Element | null => {


    const PLAYERS_HEADER_LABEL = UseTeamsDictionaryPhrasesSet("PLAYERS_HEADER_LABEL");
    const ADD_PLAYER_LABEL = UseTeamsDictionaryPhrasesSet("ADD_PLAYER_LABEL");


    const [addTeamPlayerFormStatus, setAddTeamPlayerFormStatus] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setAddTeamPlayerFormStatus(false);
        };
    }, []);


    if (!team) return null;


    const {
        team_id: teamID, url: teamApiURL, team_players: teamPlayers
    } = team;


    return (
        <Col xs={12} className={"user-profile-captain-team player-profile__captain-zone__team"}>
            <Row className={"user-profile-captain-team-content player-profile__captain-zone__team__content"}>

                <TeamCaptainTeamDataComponent
                    team={team}
                    updatePlayerTrigger={updatePlayerTrigger}
                />


                <Col xs={12} className={"player-profile__captain-zone__team__players captain-zone-team-players"}>
                    <Row className={"player-profile__captain-zone__team__players__content captain-zone-team-players__content"}>

                        <Col as={"h2"} xs={12} className={"player-profile__captain-zone__team__players__header captain-zone-team-players__header"}>
                            <span>{PLAYERS_HEADER_LABEL}</span>
                        </Col>

                        <TeamCaptainTeamPlayersComponent
                            teamID={teamID}
                            playerID={playerID}
                            teamPlayers={teamPlayers}
                            updatePlayerTrigger={updatePlayerTrigger}
                        />

                        {!addTeamPlayerFormStatus ?
                        <Col xs={12} className={"player-profile__captain-zone__team__players__add-player captain-team-add-player-button"}>
                           <Row className={"player-profile__captain-zone__team__players__add-player__header"}>
                               <span className={"header-label"}>{ADD_PLAYER_LABEL}</span>
                               <span onClick={() => setAddTeamPlayerFormStatus(true)} className={"header-action"}>
                                    <AiOutlinePlus/>
                                </span>
                           </Row>
                        </Col>
                        :
                        <TeamCaptainAddTeamPlayerComponent
                            teamCaptainID={playerID}
                            teamCaptainTeamID={teamID}
                            addPlayerShowStatusHandler={setAddTeamPlayerFormStatus}
                            updatePlayerTrigger={updatePlayerTrigger}
                        />
                        }

                    </Row>
                </Col>

                <TeamCaptainTeamFilesComponent
                    teamApiURL={teamApiURL}
                />

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamComponent;
