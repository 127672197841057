import React from "react";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import TeamHeaderLogoComponent from "@Components/Views/Pages/Games/Teams/Team/Header/Logo";
import TeamHeaderNameComponent from "@Components/Views/Pages/Games/Teams/Team/Header/Name";
import TeamHeaderLeagueComponent from "@Components/Views/Pages/Games/Teams/Team/Header/League";
import TeamHeaderSocialsComponent from "@Components/Views/Pages/Games/Teams/Team/Header/Socials";


import "./index.scss";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface TeamHeaderComponentProps {
    Team: LeagueTeamInterface;
}


export const TeamHeaderComponent: React.FC<TeamHeaderComponentProps> = ({
    Team
}) : JSX.Element | null => {


    if (!Team) return null;


    const {
        // team_id: teamID, team_slug: teamSLug,
        team_name: teamName, team_city: teamCity,
        team_league: teamLeague,
        team_logo: teamLogo,
        team_website: teamWebsite, team_socials: teamSocials
    } = Team;


    return (
        <ContainerSectionComponent classes={"team-page__team-header"}>

            <TeamHeaderLogoComponent
                teamLogo={teamLogo}
            />

            <TeamHeaderNameComponent
                teamName={teamName}
                teamCity={teamCity}
            />

            {!!teamLeague &&
	        <TeamHeaderLeagueComponent
		        teamLeague={teamLeague}
	        />
            }

            <TeamHeaderSocialsComponent
                teamSocials={teamSocials}
                teamWebsite={teamWebsite}
            />

        </ContainerSectionComponent>
        );

};


export default TeamHeaderComponent;
