import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


const TEXTS: any = {
    PL: {go_to_team_page_text: "Przejdź do strony drużyny"},
    EN: {go_to_team_page_text: "Go to team page"}
};


import LeagueTableTeamType from "@DataGenerators/Leagues/Tables/_Types/Teams/Team";


export interface LeagueTableTeamComponentProps {
    team: LeagueTableTeamType;
    teamPlace: number;
    amateurLeagueStatus: boolean;
}

export const LeagueTableTeamComponent: React.FC<LeagueTableTeamComponentProps> = ({
    team, teamPlace, amateurLeagueStatus = true
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {go_to_team_page_text: GO_TO_TEAM_PAGE_TEXT} = TEXTS[activeLanguage];


    if (!team) return null;


    const {
        teamSlug,
        teamName, teamLogo,
        teamPoints, teamWins, teamLoses,
        teamSmallPointsIn, teamSmallPointsOut,
        teamSmallPointsDiff
    } = team;

    const teamGames = teamWins + teamLoses;


    return (
        <Col xs={12} className={"league-table-team league-table-row"}>
            <Row className={"league-table-team-content league-table-row-content"}>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-position"}>
                    <span>{teamPlace}</span>
                </Col>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-logo"}>
                    <NavLink to={`/druzyny/${teamSlug}`} title={GO_TO_TEAM_PAGE_TEXT}>
                        {!!teamLogo && <img src={teamLogo} alt={""} />}
                    </NavLink>
                </Col>

                <Col xs={8} lg={`auto`} className={"league-table-cell team-name"}>
                    <NavLink to={`/druzyny/${teamSlug}`} title={GO_TO_TEAM_PAGE_TEXT}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <Col xs={2} lg={`auto`} className={"league-table-cell team-points"}>
                    <span>{teamPoints}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-games"}>
                    <span>{teamGames}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-balance"}>
                    <span>{teamWins} - {teamLoses}</span>
                </Col>

                {!amateurLeagueStatus &&
				<Col xs={`auto`} className={"league-table-cell team-home-games"}>
					<span></span>
				</Col>
                }

                {!amateurLeagueStatus &&
				<Col xs={`auto`} className={"league-table-cell team-away-games"}>
					<span></span>
				</Col>
                }

                <Col xs={`auto`} className={"league-table-cell team-small-points"}>
                    <span>{teamSmallPointsIn} - {teamSmallPointsOut}</span>
                </Col>

                <Col xs={`auto`} className={"league-table-cell team-small-points-diff"}>
                    <span>{teamSmallPointsDiff > 0 ? "+" : ""}{teamSmallPointsDiff}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default LeagueTableTeamComponent;
