import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import Dictionary from "@GlobalData/Localization/Dictionary";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import "./index.scss";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";


export interface TeamHeaderLeagueComponentProps {
    teamLeague: LeagueInterface;
}


export const TeamHeaderLeagueComponent: React.FC<TeamHeaderLeagueComponentProps> = ({
    teamLeague
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        word_league_text: WORD_LEAGUE_TEXT,
        link_go_to_league_page_text: LINK_GO_TO_LEAGUE_PAGE_TEXT
    } = Dictionary[activeLanguage];


    if (!teamLeague) return null;


    const {
        league_season_id: teamLeagueSeasonID, league_id: teamLeagueID,
        league_name: teamLeagueName
    } = teamLeague;


    return (
        <Col xs={12} lg={{span: 3, offset: 1}} className={"team-page__team-header__team-league"}>
            <Row className={"team-league-content"}>

                <Col xs={"auto"} lg={12} className={"team-league-label"}>
                    <span>{WORD_LEAGUE_TEXT}:</span>
                </Col>

                <Col xs={"auto"} lg={12} className={"team-league-name"}>
                    <NavLink
                        to={{
                            pathname: "/tabele", state: {
                                selectedSeason: teamLeagueSeasonID,
                                selectedLeague: teamLeagueID
                            }
                        }}
                        title={LINK_GO_TO_LEAGUE_PAGE_TEXT}
                    >
                        <span>{teamLeagueName}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamHeaderLeagueComponent;
